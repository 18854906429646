import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchIntegrations,
  addIntegration,
  updateIntegration,
  deleteIntegration,
  addOktaIntegration,
  fetchOktaIntegrations,
  addSlackIntegration,
  fetchSlackIntegrations,
  deleteSlackIntegration,
  updateJiraIntegration,
  updateSlackIntegration,
  addWizIntegration,
  updateWizIntegration,
  deleteWizIntegration,
  wizIntegrationStatus,
} from "../apis/integrationAPI";

const initialState = {
  integrations: {},
  oktaIntegrations: [],
  slackIntegrations: [],
};

// Async calls.
export const listIntegrationsAsync = createAsyncThunk(
  "integration/list",
  async (user_id) => {
    const response = await fetchIntegrations(user_id);
    return response.data;
  }
);

// Async calls.
export const listOktaIntegrationsAsync = createAsyncThunk(
  "cognitoOktaIntegration",
  async (user_id) => {
    const response = await fetchOktaIntegrations(user_id);
    return response.data;
  }
);

// Async calls.
export const listSlackIntegrationsAsync = createAsyncThunk(
  "slackIntegration",
  async () => {
    const response = await fetchSlackIntegrations();
    return response.data;
  }
);

export const addIntegrationAsync = createAsyncThunk(
  "integration/addIntegration",
  async (params) => {
    const response = await addIntegration(params);
    return response.data;
  }
);

export const updateIntegrationAsync = createAsyncThunk(
  "integration/updateIntegration",
  async (params) => {
    const response = await updateIntegration(params.id, { name: params.name });
    return response.data;
  }
);

export const updateSlackIntegrationAsync = createAsyncThunk(
  "integration/updateSlackIntegration",
  async (params) => {
    const response = await updateSlackIntegration(params.id, {
      name: params.name,
    });
    return response.data;
  }
);

export const updateWizIntegrationAsync = createAsyncThunk(
  "integration/updateWizIntegration",
  async (params) => {
    const response = await updateWizIntegration(params.id, {
      client_id: params.client_id,
      client_secret: params.client_secret,
      endpoint_url: params.endpoint_url,
      auth_url: params.auth_url,
      userId: params.userId,
    });
    return response.data;
  }
);

export const wizIntegrationStatusAsync = createAsyncThunk(
  "integration/WizIntegrationStatus",
  async (params) => {
    const response = await wizIntegrationStatus({
      customerId: params.customerId,
    });
    return response.data;
  }
);

export const deleteIntegrationAsync = createAsyncThunk(
  "integration/deleteIntegration",
  async (params) => {
    const response = await deleteIntegration(params);
    return response.data;
  }
);

export const deleteOktaIntegrationAsync = createAsyncThunk(
  "integration/cognitoOktaIntegration",
  async (params) => {
    const response = await deleteIntegration(params);
    return response.data;
  }
);

export const deleteSlackIntegrationAsync = createAsyncThunk(
  "integration/slackIntegration",
  async (params) => {
    const response = await deleteSlackIntegration(params);
    return response.data;
  }
);
export const deleteWizIntegrationAsync = createAsyncThunk(
  "integration/wizIntegration",
  async (params) => {
    const response = await deleteWizIntegration(params);
    return response.data;
  }
);

export const addOktaIntegrationAsync = createAsyncThunk(
  "integration/addOktaIntegration",
  async (params) => {
    const response = await addOktaIntegration(params);
    return response.data;
  }
);

export const addSlackIntegrationAsync = createAsyncThunk(
  "integration/addSlackIntegration",
  async (params) => {
    const response = await addSlackIntegration(params);
    return response.data;
  }
);

export const addWizIntegrationAsync = createAsyncThunk(
  "integration/addWizIntegration",
  async (params) => {
    const response = await addWizIntegration(params);
    return response.data;
  }
);

// Account slice for configuring reducers and actions.
export const integrationSlice = createSlice({
  name: "integration",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(listIntegrationsAsync.fulfilled, (state, action) => {
        state.integrations = action.payload;
      })
      .addCase(listIntegrationsAsync.rejected, (state, action) => {
        state.integrations = [];
      })
      .addCase(listOktaIntegrationsAsync.fulfilled, (state, action) => {
        state.oktaIntegrations = action.payload.data;
      })
      .addCase(listOktaIntegrationsAsync.rejected, (state, action) => {
        state.oktaIntegrations = [];
      })
      .addCase(listSlackIntegrationsAsync.fulfilled, (state, action) => {
        state.slackIntegrations = action.payload.data;
      })
      .addCase(listSlackIntegrationsAsync.rejected, (state, action) => {
        state.slackIntegrations = [];
      });
  },
});

export default integrationSlice.reducer;

// Selectors will go here
export const integrationById = (state, integrationId) =>
  state.integrations.find((integration) => integration.id === integrationId);

import React, { memo, useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import tagIcon from "../../../assets/img/icons/tagImage.svg";
import { listDataStoreTagsAsync } from "../../../redux/slices/dataStoreSlice";
import { axiosDenaliPrivate } from "../../../utils/ApiHandler";
import { getCustomerId } from "../../../utils/SessionHelper";
import { formatLine } from "../../../utils/styles";
import ManageTagsModal from "../../modal/manage-tags-modal/ManageTagsModal";
import SensitiveDataModal from "../../modal/sensitive-data/SensitiveDataModal";
import redCheck from "./redCheck.svg";
import "./TopPanelComponent.scss";
import DatastoreIssues from "../../datastore-issues/DatastoreIssues";
import AttackIcon from "../../../assets/img/icons/attack-icon.svg";
import HackerIcon from "../../../assets/img/icons/hacker-icon.svg";
import LivenessIcon from "../../../assets/img/icons/liveness-icon.svg";
import LockIcon from "../../../assets/img/icons/lock-icon.svg";
import { getDatastoreNewIcon } from "../../../utils/styles";

const TopPanelComponent = ({ props }) => {
  const dispatch = useDispatch();
  const [datastoreDescriptions, setDatastoreDescriptions] = useState([]);

  const [tags, setTags] = useState([]);

  const [moreTags, setMoreTags] = useState([]);
  const [isManageTagsModal, setIsManageTagsModal] = useState(false);
  const [isSensitiveDataModal, setIsSensitiveDataModal] = useState(false);
  const [loading, setLoadingStatus] = useState(false);

  const getSensitiveData = async () => {
    let url = `/api/v1/datastoredescription`;
    const payload = {
      id: props.datastoreId,
      customerId: getCustomerId(),
    };
    let res = await axiosDenaliPrivate.post(url, payload);
    res = res.data;
    if (res) {
      setDatastoreDescriptions(
        res?.filter((element) => element?.Automated !== "Manual")
      );
    }
  };

  /**
   * Refresh the tags
   */
  const refreshDataStoreTags = async () => {
    const res = await dispatch(
      listDataStoreTagsAsync({
        customerId: getCustomerId(),
        cloudAccountId: props.selectedBar?.cloudAccountId || "",
        datastoreId: props.datastoreId,
      })
    );
    let tagList = [];
    if (res && res.payload && !res.payload.err) {
      // Adding sensitive tag
      if (res.payload?.customerSensTag) {
        tagList.push({
          value: res.payload.customerSensTag,
          label: res.payload.customerSensTag,
          type: "sensitive",
        });
      }
      if (res.payload?.customerTags) {
        // Adding custom tag
        res.payload.customerTags.map((tag) => {
          if (
            tag.toLowerCase() === "us_access_only" ||
            tag.includes("us access only") ||
            tag.includes("us-access-only")
          ) {
            props.setIsUSOnlyAccess(true);
          }

          tagList.push({
            value: tag,
            label: tag,
            type: "custom",
          });
        });
      }
    }
    setTags(tagList);
  };

  useEffect(() => {
    getSensitiveData();
  }, []);

  useEffect(() => {
    if (props.selectedBar) {
      refreshDataStoreTags();
    }
  }, [props.selectedBar]);

  useEffect(() => {
    // Setting more tags list
    if (tags && tags.length > 3) {
      let tagList = [];
      tags.map((tag, index) => {
        if (index > 2) {
          tagList.push(tag);
        }
      });
      setMoreTags(tagList);
    }
  }, [tags]);

  return (
    <div>
      {props.toggle ? (
        <div id="top-panel-main-container" className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-5">
              <div className="workday-block">
                <div className="row">
                  <div className="col col-sm-12">
                    <div className="d-flex align-items-start">
                      <div className="">
                        <div className="col-md-12">
                          <span className="datastore-logo">
                            {getDatastoreNewIcon(
                              props.selectedBar.type,
                              props.selectedBar.score
                            )}
                          </span>

                          {/* <img src={props.logo}  /> */}
                        </div>
                        <div className="col-md-12 mt-2 align-items-center justify-content-center">
                          {props?.selectedBar?.indirectAttackStatus && (
                            <img
                              src={AttackIcon}
                              width="25px"
                              title="Indirect Anomaly"
                            />
                          )}{" "}
                        </div>
                        <div className="col-md-12 mt-2 align-items-center justify-content-center">
                          {props?.selectedBar?.attackstatus && (
                            <img
                              src={HackerIcon}
                              width="21px"
                              title="Direct Anomaly"
                            />
                          )}{" "}
                        </div>
                        <div className="col-md-12  align-items-center justify-content-center">
                          {props?.selectedBar?.livenessInfo && (
                            <img
                              src={LivenessIcon}
                              width="20px"
                              title="Live Traffic"
                            />
                          )}{" "}
                        </div>
                        <div className="col-md-12 mt-2 align-items-center justify-content-center">
                          {props?.selectedBar?.sensitiveScore >= 80 && (
                            <img
                              src={LockIcon}
                              width="20px"
                              title="Highly Sensitive"
                            />
                          )}{" "}
                        </div>
                      </div>

                      <div className="pl-3 w-100">
                        <div className="row workday-heading">
                          <div
                            className="col col-sm-12"
                            title={props.selectedBar.name}
                          >
                            {formatLine(props.selectedBar.name, 42)}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Account:
                          </div>
                          <div className="col col-sm-8 workday-subheading-value">
                            {props.selectedBar.cloudAccountId}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Type:
                          </div>
                          <div className="col col-sm-8 workday-subheading-value">
                            {props.selectedBar.type}
                          </div>
                        </div>
                        {props?.selectedBar?.engine &&
                          props?.selectedBar?.engine !== null && (
                            <div className="row">
                              <div className="col col-sm-4 workday-subheading">
                                Engine:
                              </div>

                              <div className="col col-sm-8 workday-subheading-value">
                                {props.selectedBar.engine}
                              </div>
                            </div>
                          )}{" "}
                        {props?.selectedBar?.region &&
                          props?.selectedBar?.region !== null && (
                            <div className="row">
                              <div className="col col-sm-4 workday-subheading">
                                Region:
                              </div>

                              <div className="col col-sm-8 workday-subheading-value">
                                {props.selectedBar.region}
                              </div>
                            </div>
                          )}
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Discovered On:
                          </div>
                          <div className="col col-sm-8 workday-subheading-value">
                            <Moment format="YYYY-MM-DD HH:MM:SS">
                              {props.selectedBar.discoveredOn}
                            </Moment>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Size:
                          </div>
                          <div className="col col-sm-8 workday-subheading-value">
                            {props.selectedBar.size}
                          </div>
                        </div>
                        {props?.selectedBar?.host &&
                          props?.selectedBar?.host !== null && (
                            <div className="row">
                              <div className="col col-sm-4 workday-subheading">
                                Host:
                              </div>

                              <div className="col col-sm-8 workday-subheading-value">
                                {props.selectedBar.host}
                              </div>
                            </div>
                          )}
                        {!props.selectedBar.location ||
                        props.selectedBar.location === "-" ? (
                          <div className="row"></div>
                        ) : (
                          <div className="row">
                            <div className="col col-sm-4 workday-subheading">
                              Location:
                            </div>

                            <div className="col col-sm-8 workday-subheading-value subvalue-bold text-decoration-underline">
                              {props.selectedBar.location}
                            </div>
                          </div>
                        )}{" "}
                        {props?.selectedBar?.lastModifiedAt &&
                          props?.selectedBar?.lastModifiedAt !== null && (
                            <div className="row">
                              <div className="col col-sm-4 workday-subheading">
                                Last Modified:
                              </div>

                              <div className="col col-sm-8 workday-subheading-value">
                                <Moment format="YYYY-MM-DD HH:MM:SS">
                                  {props.selectedBar.lastModifiedAt}
                                </Moment>
                              </div>
                            </div>
                          )}{" "}
                        {!props.selectedBar.administrator ||
                        props.selectedBar.administrator !== null ? (
                          <div className="row"></div>
                        ) : (
                          <div className="row">
                            <div className="col col-sm-4 workday-subheading">
                              Owner:
                            </div>

                            <div className="col col-sm-8 workday-subheading-value subvalue-bold text-decoration-underline">
                              {props.selectedBar.administrator}
                            </div>
                          </div>
                        )}{" "}
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Tags:
                          </div>
                          <div className="col col-sm-8 d-flex  workday-subheading-value">
                            <div class="row ">
                              {tags.length === 0 ? (
                                <div class="col-md-auto pl-3 p-0"> N/A </div>
                              ) : (
                                tags.map((tag, index) => (
                                  <div key={index}>
                                    {index < 3 ? (
                                      <div class="col-md-auto pl-3 p-0">
                                        <span className="subvalue-bold data-store-tag">
                                          <img
                                            className="mr-1"
                                            src={tagIcon}
                                          ></img>
                                          <span
                                            title={
                                              tag?.label.length > 12
                                                ? tag?.label
                                                : ""
                                            }
                                          >
                                            {tag?.label.length > 12
                                              ? tag?.label.slice(0, 12) + "..."
                                              : tag.label || ""}
                                          </span>
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {/* Adding more tags link */}
                                    {index == 3 && (
                                      <Popup
                                        trigger={(open) => (
                                          <div class="col-md-auto pl-3 p-0">
                                            <span className="subvalue-bold more-tags">
                                              <b>
                                                <u>more...</u>
                                              </b>
                                            </span>
                                          </div>
                                        )}
                                        position="right top"
                                        closeOnDocumentClick
                                        className="top-panel-tags"
                                      >
                                        <div className="header">Tags</div>
                                        <div class="row">
                                          {moreTags.map((tag, index) => (
                                            <div
                                              key={index}
                                              class="col-md-auto"
                                            >
                                              <div className="subvalue-bold data-store-tag m-2">
                                                <img
                                                  className="mr-2"
                                                  src={tagIcon}
                                                ></img>
                                                <span
                                                  className="subvalue-bold"
                                                  title={tag.label}
                                                >
                                                  {tag?.label.length > 25
                                                    ? tag?.label.slice(0, 25) +
                                                      "..."
                                                    : tag.label || ""}
                                                </span>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </Popup>
                                    )}
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row  action-buttons">
                          <div className="col col-sm-12 workday-subheading">
                            <button
                              type="button"
                              onClick={() => setIsManageTagsModal(true)}
                              className="btn float-left mr-3 btn-primary btn-sm my-1"
                              disabled={!props.selectedBar?.cloudAccountId}
                            >
                              Manage Tags
                            </button>
                            <button
                              type="button"
                              onClick={() => setIsSensitiveDataModal(true)}
                              className="btn   btn-primary btn-sm my-1"
                              disabled={
                                datastoreDescriptions &&
                                datastoreDescriptions.length === 0
                              }
                            >
                              Sensitive Data
                            </button>
                          </div>
                          {isManageTagsModal ? (
                            <ManageTagsModal
                              tags={tags}
                              refreshDataStoreTags={refreshDataStoreTags}
                              handleModal={() => setIsManageTagsModal(false)}
                              datastoreId={props.datastoreId}
                              cloudAccountId={props.selectedBar?.cloudAccountId}
                            />
                          ) : (
                            ""
                          )}
                          {isSensitiveDataModal && (
                            <SensitiveDataModal
                              handleModal={() => setIsSensitiveDataModal(false)}
                              datastoreDescriptions={datastoreDescriptions}
                              sensitiveScore={props.selectedBar?.sensitiveScore}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-7 pl-md-0">
              <DatastoreIssues
                assetIdValue={props.assetId}
                isAttackPath={false}
                pageName="datastore-info"
                setLoading={(val) => setLoadingStatus(val)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="top-panel-2">
          <div id="top-panel-main-container" className="container-fluid px-2">
            <div className="row">
              <div className="col col-sm-1 col-lg-1">
                <div className="number-circle"> 99</div>
              </div>
              <div className="col col-sm-3 col-md-3 col-lg-3 text-info">
                <b>CD Labs: Workday HR</b>
              </div>
              <div className="col col-sm-8 col-md-8 col-lg-8">
                <div className="row">
                  <div className="col col-md-3 col-lg-3 p-0">
                    <span className="card-text text-info">SENSITIVE DATA:</span>
                  </div>
                  <div className="col col-md-3 col-lg-3 p-0">
                    <span className="card-text">
                      <img src={redCheck} className="pr-1" />
                      <span className="card-subtext">PII - DL Number:</span>
                    </span>
                  </div>
                  <div className="col col-md-3 col-lg-3 p-0">
                    <span className="card-text">
                      <img src={redCheck} className="pr-1" />
                      PII-Login Info
                    </span>
                  </div>
                  <div className="col col-md-3 col-lg-3 p-0">
                    <span className="card-text">
                      <img src={redCheck} className="pr-1" />
                      PII-DL Number
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(TopPanelComponent);

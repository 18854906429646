import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../datastore/datastore.scss";
import moment from "moment";
import { getScoreColor } from "../../utils/styles";
import AttackIcon from "../../assets/img/icons/attack-icon.svg";
import HackerIcon from "../../assets/img/icons/hacker-icon.svg";
import LivenessIcon from "../../assets/img/icons/liveness-icon.svg";
import LockIcon from "../../assets/img/icons/lock-icon.svg";
import { useEdges } from "react-flow-renderer";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import Popup from "reactjs-popup";
import AcceptRisk from "../../assets/img/icons/risk-accept.svg";
import TicketIcon from "../../assets/img/v1-icons/view-ticket.svg";
import DeleteTicketIcon from "../../assets/img/v1-icons/delete-ticket.svg";
import TicketUnavailableIcon from "../../assets/img/v1-icons/create-ticket.svg";
import NotifyIcon from "../../assets/img/v1-icons/notify.svg";
import Archive from "../../assets/img/v1-icons/archive.svg";

import { deleteTicketAsync } from "../../redux/slices/ticketSlice";
import DatastoreTicketModal from "../modal/datastore-ticket-modal/DatastoreTicketModal";
import DeleteConfirmationModal from "../modal/shared/DeleteConfirmationModal";
import {
  acceptPolicyViolationAsync,
  policyNotifyAsync,
  archivePolicyAsync,
} from "../../redux/slices/policySlice";
import { dateFormatter } from "../../utils/dataHandler";
import { json } from "d3";
const Table = ({
  currentPosts,
  refreshDatastores,
  policyViolationId,
  severity,
  setLoading,
  page = "datastore-details",
  sortData,
  ticketDetail,
  tablePadding = "px-3",
}) => {
  const history = useHistory();
  const [ticketModal, setTicketModal] = useState(false);
  const [ticketModalInfo, setTicketModalInfo] = useState(false);
  const [isPolicyViolationAccepted, setIsPolicyViolationAccepted] = useState(
    false
  );
  const [isPolicyArchive, setIsPolicyArchive] = useState(false);
  const [acceptedRiskPolicy, setAcceptedRiskPolicy] = useState();
  const [datastoreID, setDatastoreID] = useState("");
  const dispatch = useDispatch();
  const policy = JSON.parse(sessionStorage.getItem("policy"));
  const viewDetails = (item) => {
    sessionStorage.setItem("selected_bar", JSON.stringify(item));
    const path = item.type === "llm" ? "llm" : "datastore-details";
    if (policyViolationId) {
      history.push(
        `/${path}/${item.datastoreId?.replaceAll(
          "/",
          "_"
        )}/${item.assetId?.replaceAll("/", "_")}/${policyViolationId}`
      );
    } else {
      history.push(
        `/${path}/${item.datastoreId?.replaceAll(
          "/",
          "_"
        )}/${item.assetId?.replaceAll("/", "_")}`
      );
    }
  };

  const getSensitiveAlias = (name) => {
    const names = name.split("_");
    return names.length > 2
      ? `${names[0].charAt(0)}${names[1].charAt(0)}${names[2].charAt(0)}`
      : names.length > 1
      ? `${names[0].charAt(0)}${names[1].charAt(0)}`
      : `${names[0].charAt(0)}`;
  };

  const formatedSensitiveInfo = (info) => {
    const names = [];
    const labels = [];
    for (let i of info) {
      if (names.length < 3) {
        names.push({
          name: getSensitiveAlias(i.name).toUpperCase(),
          count: i.count,
        });
        labels.push(getSensitiveAlias(i.name).toUpperCase());
      }
    }
    const result = { label: labels.join(", "), values: names };
    return result;
  };

  const getCount = (info) => {
    console.log(info);
  };

  const deleteTicket = async (item) => {
    setLoading(true);
    const payload = {
      customerId: getCustomerId(),
      policyViolationId: policyViolationId ? policyViolationId : null,
      datastoreId: item.datastoreId,
    };

    let resp = await dispatch(deleteTicketAsync(payload));
    if (resp && resp.payload && !resp.payload.err) {
      setLoading(false);
      Toaster(TOASTER_TYPES.SUCCESS, resp.payload.success.message);
      refreshDatastores();
    }
  };

  const notifyPolicy = async (item) => {
    setLoading(true);
    const payload = {
      customerId: getCustomerId(),
      policyViolationId: policy?.id ? policy?.id : null,
      datastoreId: item.datastoreId,
    };
    let resp = await dispatch(policyNotifyAsync(payload));
    if (resp && resp.payload && !resp.payload.err) {
      Toaster(TOASTER_TYPES.SUCCESS, resp.payload.success.message);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const archivePolicy = async (item) => {
    setLoading(true);
    setIsPolicyArchive(false);
    const payload = {
      customerId: getCustomerId(),
      policyViolationId: policy?.id ? policy?.id : null,
      datastoreId: [datastoreID],
    };
    let resp = await dispatch(archivePolicyAsync(payload));
    if (resp || resp === null) {
      setLoading(false);
      refreshDatastores();
    }
  };

  const acceptPolicyViolation = async () => {
    setLoading(true);
    setIsPolicyViolationAccepted(false);

    const payload = {
      customerId: getCustomerId(),
      policyViolationId: policyViolationId ? policyViolationId : null,
      datastoreId: acceptedRiskPolicy.datastoreId,
    };

    let resp = await dispatch(acceptPolicyViolationAsync(payload));
    if (resp) {
      setLoading(false);
      refreshDatastores();
    }
  };

  return (
    <div className="row">
      <div className="col col-xl-12">
        <div className="table-responsive">
          <table
            className={`mb-0 pb-3 table table-border-less table-hover ${tablePadding}`}
          >
            <thead>
              <tr className="vuls-row">
                <th
                  scope="col"
                  onClick={() => sortData("cdDatastoreRiskScore")}
                >
                  Data Risk
                  <i className="las la-sort"></i>
                </th>
                <th scope="col" onClick={() => sortData("name.keyword")}>
                  Name <i className="las la-sort"></i>
                </th>
                <th
                  scope="col"
                  onClick={() => sortData("metadata.cloudAccountId.keyword")}
                >
                  Account Id <i className="las la-sort"></i>
                </th>
                <th
                  scope="col"
                  onClick={() => sortData("metadata.cloudAccountId.keyword")}
                >
                  Category <i className="las la-sort"></i>
                </th>
                <th scope="col" onClick={() => sortData("type.keyword")}>
                  Type <i className="las la-sort"></i>
                </th>
                <th
                  scope="col"
                  onClick={() => sortData("metadata.createdAt.keyword")}
                >
                  Discovered On <i className="las la-sort"></i>
                </th>
                <th scope="col" onClick={() => sortData("size.keyword")}>
                  Size <i className="las la-sort"></i>
                </th>
                <th
                  scope="col"
                  onClick={() => sortData("mountLocation.keyword")}
                >
                  Sensitive <i className="las la-sort"></i>
                </th>
                {policyViolationId && (
                  <th scope="col" style={{ minWidth: "100px" }}>
                    Actions
                  </th>
                )}

                {page === "datastore-details" && <th scope="col"></th>}
              </tr>
            </thead>

            <tbody>
              {currentPosts.map(function (item, idx) {
                return (
                  <tr
                    key={idx}
                    onClick={() => viewDetails(item)}
                    className="vuls-row"
                  >
                    <td>
                      <div
                        className={`${getScoreColor(
                          item.cdDatastoreRiskScore
                        )}`}
                      ></div>
                    </td>
                    <td className="data-store-name">
                      <div className="wrap-with-ellipsis" title={item?.name}>
                        {item?.name}
                      </div>
                    </td>
                    <td className="table-data">
                      {" "}
                      <div
                        className="wrap-with-ellipsis"
                        title={item?.["metadata.cloudAccountId"]}
                      >
                        {item?.["metadata.cloudAccountId"]}
                      </div>
                    </td>
                    <td className="table-type">{item.datastoreType}</td>

                    <td className="table-type">{item.type}</td>
                    <td className="table-data">
                      {item["metadata.createdAt"]
                        ? dateFormatter(item["metadata.createdAt"])
                        : "-"}
                    </td>
                    <td className="table-data">{item.size}</td>
                    <td>
                      {formatedSensitiveInfo(item.sensitiveInfo).label.length >
                      9 ? (
                        <Popup
                          trigger={
                            <a href="#" className="data-store-name">
                              {formatedSensitiveInfo(
                                item.sensitiveInfo
                              ).label.slice(0, 9)}
                            </a>
                          }
                          on={["hover"]}
                          position="bottom center"
                          arrow={true}
                          className="senstitive-tags-popup"
                        >
                          <div className="popup-item">
                            {formatedSensitiveInfo(item.sensitiveInfo).label}
                          </div>
                        </Popup>
                      ) : (
                        <a href="#" className="data-store-name">
                          {formatedSensitiveInfo(item.sensitiveInfo).label}
                        </a>
                      )}
                    </td>
                    {page === "datastore-details" && (
                      <td>
                        {" "}
                        {item?.indirectAttackStatus && (
                          <img
                            src={AttackIcon}
                            width="25px"
                            title="Indirect Anomaly"
                          />
                        )}{" "}
                        {item?.attackstatus && (
                          <img
                            src={HackerIcon}
                            width="21px"
                            title="Direct Anomaly"
                            className="mr-1"
                          />
                        )}{" "}
                        {item?.livenessInfo && (
                          <img
                            src={LivenessIcon}
                            width="20px"
                            className="mr-1"
                            title="Live Traffic"
                          />
                        )}{" "}
                        {item?.sensitiveScore >= 80 && (
                          <img
                            src={LockIcon}
                            width="20px"
                            title="Highly Sensitive"
                          />
                        )}
                      </td>
                    )}
                    {policy?.actionType === "ticket/accept" &&
                      policyViolationId &&
                      item.tickets === 0 && (
                        <td
                          onClick={(e) => e.stopPropagation()}
                          className={
                            !item.ticketIntegration && "cursor-default"
                          }
                        >
                          <img
                            title="Create Ticket"
                            src={TicketUnavailableIcon}
                            className={
                              item.ticketIntegration
                                ? "ticket-icon mr-3  cursor-pointer"
                                : "ticket-icon-disable mr-3 "
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (item.ticketIntegration) {
                                setTicketModal(true);
                                setTicketModalInfo({
                                  datastoreId: item.datastoreId,
                                  policyViolationId: policyViolationId,
                                });
                              }
                            }}
                          />{" "}
                          <img
                            src={AcceptRisk}
                            title="Accept Policy Violation"
                            width="25px"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsPolicyViolationAccepted(true);
                              setAcceptedRiskPolicy(item);
                            }}
                          />
                        </td>
                      )}
                    {policy?.actionType === "ticket/accept" &&
                      policyViolationId &&
                      item.tickets > 0 && (
                        <td onClick={(e) => e.stopPropagation()}>
                          <img
                            title="Ticket Details"
                            src={TicketIcon}
                            className="ticket-icon mr-1 mr-3  cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(item?.url ? item?.url : "#");
                            }}
                          />
                          <img
                            src={AcceptRisk}
                            title="Accept Policy Violation"
                            width="25px"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsPolicyViolationAccepted(true);
                              setAcceptedRiskPolicy(item);
                            }}
                          />

                          {/* <img
                          title="Delete Ticket"
                          src={DeleteTicketIcon}
                          className="ticket-icon ml-1 mb-1 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();

                            deleteTicket(item);
                          }}
                        /> */}
                        </td>
                      )}
                    {policyViolationId &&
                      policy?.actionType === "notify/archive" && (
                        <td onClick={(e) => e.stopPropagation()}>
                          <img
                            title="Notify"
                            src={NotifyIcon}
                            className="ticket-icon mr-1 mr-3  cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              notifyPolicy(item);
                            }}
                          />
                          <img
                            title="Archive"
                            src={Archive}
                            width="25px"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDatastoreID(item.datastoreId);
                              setIsPolicyArchive(true);
                              // archivePolicy(item);
                              // setIsPolicyViolationAccepted(true);
                              // setAcceptedRiskPolicy(item);
                            }}
                          />
                        </td>
                      )}
                  </tr>
                );
              })}
              {ticketModal && (
                <DatastoreTicketModal
                  type="datastore"
                  refreshDatastores={refreshDatastores}
                  datastoreId={ticketModalInfo.datastoreId}
                  policyViolationId={ticketModalInfo.policyViolationId}
                  handleModal={() => {
                    setTicketModal(false);
                  }}
                />
              )}
              {isPolicyArchive && (
                <DeleteConfirmationModal
                  handleModal={() => setIsPolicyArchive(false)}
                  deleteHandle={archivePolicy}
                  modalData={{
                    title: "Archive Policy",
                    message: "Are you sure, you want to archive this policy ?",
                    type: "archive",
                  }}
                />
              )}
              {isPolicyViolationAccepted && (
                <DeleteConfirmationModal
                  handleModal={() => setIsPolicyViolationAccepted(false)}
                  deleteHandle={acceptPolicyViolation}
                  modalData={{
                    title: "Accept Policy",
                    message:
                      "Are you sure, you want to accept this policy violation ?",
                    type: "accept",
                  }}
                />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default memo(Table);

import REDCURVE from "../../../assets/img/curves/red.svg";
import SKYCURVE from "../../../assets/img/curves/sky.svg";
import YELLOWCURVE from "../../../assets/img/curves/yellow.svg";
import infoFilled from "../../../assets/img/icons/ep-info-filled.svg";
import Card from "./card";
import StepsCard from "./StepsCard";
import { useParams } from "react-router-dom";

import "./risk-card.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listDatastoreStats,
  listIssuesStats,
  firstAccountStatStatus,
  listAssetsStats,
  listIdentitiesStats,
} from "../../../redux/slices/dashboardSlice";
import {
  showLoading,
  hideLoading,
} from "../../../redux/slices/globalOperationSlice";
import {
  getCustomerId,
  getSelectedDatastoreAccount,
  getSelectedIssuesAccount,
} from "../../../utils/SessionHelper";

const RiskCards = ({
  assets,
  identities,
  vunerability,
  severity,
  filterIssues,
  setRiskScore,
  accountId = undefined,
}) => {
  const dispatch = useDispatch();
  const firstAccountStatus = useSelector(
    (state) => state.dashboard.firstAccountStatus
  );

  const accounts = useSelector((state) => state.account.accounts);
  const showShadowAI = localStorage.getItem("showShadowAI");
  const params = useParams();
  const filterName = params.filterName;
  const userCards = [
    {
      id: "TotalUsers",
      title: "TOTAL USERS",
      label: "USERS",
      arrow: "ri-arrow-up-line text-danger mr-1",
      curve: REDCURVE,
      score: 0,
      scoreClass: "text-danger",
      severity: "High",
    },
    {
      id: "TotalRoles",
      title: "TOTAL ROLES",
      label: "TOTAL ROLES",
      arrow: "ri-arrow-down-line text-info mr-1",
      curve: YELLOWCURVE,
      score: 0,
      scoreClass: "score-moderate-level",
      severity: "Medium",
    },
    {
      id: "crossAccessUsers",
      title: "CROSS ACCESS USERS",
      label: "CROSS ACCESS USERS",
      arrow: "ri-arrow-up-line text-danger mr-1",
      curve: REDCURVE,
      score: 0,
      scoreClass: "text-info",
      severity: "Low",
    },
    {
      id: "crossAccessRoles",
      title: "CROSS ACCESS ROLES",
      label: "CROSS ACCESS ROLES",
      arrow: "ri-arrow-down-line text-info mr-1",
      curve: SKYCURVE,
      score: 0,
      scoreClass: "text-info",
      severity: null,
    },
  ];
  const issuesCards = [
    {
      id: "urgentIssues",
      title: "URGENT ISSUES",
      label: "URGENT",
      arrow: "ri-arrow-up-line text-danger mr-1",
      curve: REDCURVE,
      score: 0,
      scoreClass: "text-danger",
      severity: "High",
    },
    {
      id: "importantIssues",
      title: "IMPORTANT ISSUES",
      label: "IMPORTANT",
      arrow: "ri-arrow-down-line text-info mr-1",
      curve: YELLOWCURVE,
      score: 0,
      scoreClass: "score-high-level",
      severity: "Medium",
    },
    {
      id: "newIssues",
      title: "NEW ISSUES",
      label: "NEW",
      arrow: "ri-arrow-up-line text-danger mr-1",
      curve: REDCURVE,
      score: 0,
      scoreClass: "text-info",
      severity: "Low",
    },
    {
      id: "TotalIssues",
      title: "TOTAL ISSUES",
      label: "TOTAL",
      arrow: "ri-arrow-down-line text-info mr-1",
      curve: SKYCURVE,
      score: 0,
      scoreClass: "text-info",
      severity: null,
    },
  ];
  const assetsCards = [
    {
      id: "UrgentRisk",
      title: "URGENT RISKS",
      label: "URGENT RISKS",
      arrow: "ri-arrow-up-line text-danger mr-1",
      curve: REDCURVE,
      score: 0,
      scoreClass: "text-danger",
      severity: "High",
    },
    {
      id: "Types",
      title: "ASSET TYPES",
      label: "ASSET TYPES",
      arrow: "ri-arrow-down-line text-info mr-1",
      curve: YELLOWCURVE,
      score: 0,
      scoreClass: "score-moderate-level",
      severity: "Medium",
      disabled: true,
    },
    {
      id: "Location",
      title: "REGIONS",
      label: "REGIONS",
      arrow: "ri-arrow-up-line text-danger mr-1",
      curve: REDCURVE,
      score: 0,
      scoreClass: "text-info",
      severity: "Low",
      disabled: true,
    },
    {
      id: "Total",
      title: "TOTAL ASSETS",
      label: "TOTAL ASSETS",
      arrow: "ri-arrow-down-line text-info mr-1",
      curve: SKYCURVE,
      score: 0,
      scoreClass: "text-info",
      severity: null,
    },
  ];
  const stepCardsData = [
    {
      id: "TotalDataStores",
      stepNumber: 1,
      stepNumberClass: "card-number-info",
      title: "TOTAL DATA STORES",
      label: "TOTAL DATA STORES",
      score: 0,
      state: "enable",
      scoreClass: "text-info",
      datastoreStatsKey: "TotalDataStores",
      stepCompleted:
        firstAccountStatus &&
        firstAccountStatus.first_acct_classification_complete,
    },
    {
      id: "sensitiveData",
      stepNumber: 2,
      stepNumberClass: "card-number-warning",
      title: "SENSITIVE DATA",
      label: "SENSITIVE DATA",
      score: 0,
      state: "enable",
      scoreClass: "score-moderate-level",
      datastoreStatsKey: "importantRisk",
      stepCompleted:
        firstAccountStatus &&
        firstAccountStatus.first_acct_classification_complete,
    },
    {
      id: "highRiskStore",
      stepNumber: 3,
      stepNumberClass: "card-number-danger",
      title: "HIGH RISK STORES",
      label: "HIGH RISK STORES",
      score: 0,
      state: `${
        firstAccountStatus &&
        firstAccountStatus.first_acct_classification_complete
          ? "enable"
          : "disable"
      }`,
      scoreClass: "text-danger",
      datastoreStatsKey: "urgentRisk",
      stepCompleted:
        firstAccountStatus && firstAccountStatus.first_acct_risk_complete,
    },
  ];
  const datastoresCards = [
    {
      id: "sensitive",
      title: "SENSITIVE",
      label: "SENSITIVE DATA",
      arrow: "ri-arrow-up-line text-danger mr-1",
      curve: REDCURVE,
      score: 0,
      scoreClass: "text-danger",
      severity: "sensitive",
      seperator: true,
    },
    {
      id: "shadow",
      title: "SHADOW",
      label: "SHADOW DATASTORES",
      arrow: "ri-arrow-down-line text-info mr-1",
      curve: SKYCURVE,
      score: 0,
      scoreClass: "text-info",
      severity: "SHADOW",
      seperator: false,
    },
    {
      id: "shadowAI",
      title: "SHADOW AI",
      label: "SHADOW AI",
      arrow: "ri-arrow-down-line text-info mr-1",
      curve: YELLOWCURVE,
      score: 0,
      scoreClass: "score-moderate-level",
      severity: "SHADOW_AI",
      seperator: true,
    },
    {
      id: "urgentRisk",
      title: "URGENT RISK",
      label: "URGENT RISK",
      arrow: "ri-arrow-up-line text-danger mr-1",
      curve: REDCURVE,
      score: 0,
      scoreClass: "text-danger",
      severity: "URGENT_RISK",
      seperator: false,
    },
    {
      id: "managed",
      title: "MANAGED",
      label: "MANAGED",
      arrow: "ri-arrow-up-line text-info mr-1",
      curve: SKYCURVE,
      score: 0,
      scoreClass: "text-info",
      severity: "MANAGED",
      seperator: false,
    },
  ];
  const llmDatastoresCards = [
    {
      id: "llms",
      title: "MANAGED",
      label: "MANAGED",
      arrow: "ri-arrow-up-line text-danger mr-1",
      curve: REDCURVE,
      score: 3,
      scoreClass: "text-danger",
      severity: "LLMs",
      seperator: true,
    },
    {
      id: "sensitive_data",
      title: "SENSITIVE",
      label: "SENSITIVE DATA",
      arrow: "ri-arrow-down-line text-info mr-1",
      curve: SKYCURVE,
      score: 4,
      scoreClass: "text-info",
      severity: "sensitive_data",
      seperator: false,
    },
    {
      id: "shadowAI",
      title: "SHADOW ",
      label: "SHADOW ",
      arrow: "ri-arrow-down-line text-info mr-1",
      curve: YELLOWCURVE,
      score: 1,
      scoreClass: "score-moderate-level",
      severity: "SHADOW_AI",
      seperator: true,
    },
    {
      id: "vectorDBs",
      title: "VECTOR DB",
      label: "VECTOR DB",
      arrow: "ri-arrow-up-line text-danger mr-1",
      curve: REDCURVE,
      score: 1,
      scoreClass: "text-danger",
      severity: "VECTOR_DB",
      seperator: false,
    },
    {
      id: "policy-violations",
      title: "POLICY VIOLATIONS",
      label: "POLICY VIOLATIONS",
      arrow: "ri-arrow-up-line text-info mr-1",
      curve: SKYCURVE,
      score: 4,
      scoreClass: "text-info",
      severity: "POLICY_VIOLATIONS",
      seperator: false,
    },
  ];
  const [stepCards, setStepCards] = useState(stepCardsData);
  const [scoreCards, setScoreCards] = useState(
    filterName ? datastoresCards : llmDatastoresCards
  );
  const datastoreStats = useSelector((state) => state.dashboard.datastoreStats);
  const issuesStats = useSelector((state) => state.dashboard.issuesStats);
  const assetsStats = useSelector((state) => state.dashboard.assetsStats);
  const identitiesStats = useSelector(
    (state) => state.dashboard.identitiesStats
  );

  const getFirstAccountStats = () => {
    const payload = {
      customerId: getCustomerId(),
    };

    dispatch(firstAccountStatStatus(payload));
  };

  const getCloudAccountId = (selectedAccountId, accountFromStorage) => {
    if (accountId) {
      return selectedAccountId
        ? selectedAccountId === "all"
          ? null
          : selectedAccountId
        : accountFromStorage
        ? accountFromStorage.value === "all"
          ? null
          : accountFromStorage.value
        : accountId === "all"
        ? null
        : accountId;
    } else {
      return null;
    }
  };

  const getStats = async (selectedAccountId) => {
    const query = new URLSearchParams(window.location.search);
    const location = query.get("region");

    dispatch(showLoading());
    const selectedDsAccount = getSelectedDatastoreAccount();
    const selectedIssueAccount = getSelectedIssuesAccount();
    if (assets) {
      await dispatch(
        listAssetsStats({
          customerId: getCustomerId(),
          cloudAccountId: getCloudAccountId(
            selectedAccountId,
            selectedIssueAccount
          ),
        })
      );
    } else if (identities) {
      await dispatch(
        listIdentitiesStats({
          customerId: getCustomerId(),
          cloudAccountId: getCloudAccountId(
            selectedAccountId,
            selectedIssueAccount
          ),
        })
      );
    } else if (vunerability) {
      await dispatch(
        listIssuesStats({
          customerId: getCustomerId(),
          cloudAccountId: getCloudAccountId(
            selectedAccountId,
            selectedIssueAccount
          ),
        })
      );
    } else {
      await dispatch(
        listDatastoreStats({
          customerId: getCustomerId(),
          location: location,
          cloudAccountId: getCloudAccountId(
            selectedAccountId,
            selectedDsAccount
          ),
        })
      );
    }
    dispatch(hideLoading());
  };

  const getAccountName = () => {
    if (accounts && accounts.length > 0) {
      return accounts[0].id;
    }
    return "";
  };

  // useEffect(async () => {
  //   if (!datastoreStats || !issuesStats) getStats();
  // }, [vunerability]);

  useEffect(() => {
    let firstAccountInterval = null;
    if (!firstAccountStatus?.first_acct_risk_complete && !accountId) {
      getFirstAccountStats();
      firstAccountInterval = setInterval(() => {
        getFirstAccountStats();
      }, 30000);
    }
    const intervalCall = setInterval(() => {
      getStats();
    }, 30000);
    return () => {
      // clean up
      clearInterval(intervalCall);
      if (firstAccountInterval) {
        clearInterval(firstAccountInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (accountId) {
      getStats(accountId);
    }
  }, [accountId]);

  useEffect(() => {
    console.log(scoreCards, "scoreCards");
  }, [scoreCards]);

  const dStats = () => {
    if (datastoreStats && !vunerability) {
      for (let key in datastoreStats) {
        if (datastoreStats.hasOwnProperty(key)) {
          let val = datastoreStats[key];
          let index = datastoresCards.findIndex((item) => item.id === key);
          if (datastoresCards[index]) {
            datastoresCards[index].score = val;
          }

          if (
            firstAccountStatus &&
            !firstAccountStatus.first_acct_risk_complete
          ) {
            let index = stepCardsData.findIndex(
              (item) => item.datastoreStatsKey === key
            );
            if (stepCardsData[index]) {
              stepCardsData[index].score = val;
            }
          }
        }
      }

      let updatedDatastoreCard = [...datastoresCards];
      if (showShadowAI == "false") {
        updatedDatastoreCard = updatedDatastoreCard.filter(
          (card) => card.id !== "shadowAI"
        );
      }
      setScoreCards([...updatedDatastoreCard]);
      setStepCards([...stepCardsData]);
    }
  };

  const iStats = () => {
    if (issuesStats && vunerability) {
      for (let key in issuesStats) {
        if (issuesStats.hasOwnProperty(key)) {
          let val = issuesStats[key];
          let index = issuesCards.findIndex((item) => item.id === key);
          issuesCards[index].score = val;
        }
      }
      setScoreCards([...issuesCards]);
    }
  };

  const aStats = () => {
    for (let key in assetsStats) {
      if (assetsStats.hasOwnProperty(key)) {
        let val = assetsStats[key];
        let index = assetsCards.findIndex((item) => item.id === key);
        assetsCards[index].score = val;
      }
    }
    setScoreCards([...assetsCards]);
  };

  const uStats = () => {
    for (let key in identitiesStats) {
      if (identitiesStats.hasOwnProperty(key)) {
        let val = identitiesStats[key];
        let index = userCards.findIndex((item) => item.id === key);

        if (userCards[index]) {
          userCards[index].score = val;
        }
      }
    }
    setScoreCards(userCards);
  };

  useEffect(() => {
    if (filterName && filterName === "SHADOW_AI") {
      setScoreCards(llmDatastoresCards);
    } else {
      dStats();
    }
  }, [datastoreStats]);

  useEffect(() => {
    iStats();
  }, [issuesStats]);

  useEffect(() => {
    aStats();
  }, [assetsStats]);

  useEffect(() => {
    if (assets) {
      aStats();
    } else if (vunerability) {
      iStats();
    } else {
      dStats();
    }
  }, [vunerability, assets]);

  return (
    <div class="row">
      {firstAccountStatus?._id && accounts.length > 0 ? (
        <div class="col-lg-12">
          {firstAccountStatus &&
          !firstAccountStatus?.first_acct_risk_complete &&
          !accountId ? (
            <div class="row risk-card-container">
              {stepCards.map((card, index) => (
                <StepsCard
                  title={card.title}
                  curve={card.curve}
                  score={card.score}
                  state={card.state}
                  scoreClass={card.scoreClass}
                  stepNumberClass={card.stepNumberClass}
                  stepNumber={card.stepNumber}
                  key={index}
                  stepCompleted={card.stepCompleted}
                  label={card.label}
                />
              ))}

              <div class="col-lg-5 pb-3">
                <div className="discovering-text d-inline-flex">
                  {/* <div className="ml-3 vr-line" /> */}
                  <img src={infoFilled} className="align-self-start" />
                  <div>
                    {firstAccountStatus?.first_acct_classification_complete ? (
                      <p className=" ">
                        We are discovering Issues Attack Paths to your Topology.
                        Based on our discoveries, Cloud Defense ranks each Data
                        Store’s Risk by the Sensitivity of Data, Risk of Issues
                        and how many Attack Paths lead to the Data Store.
                      </p>
                    ) : (
                      <p className=" ">
                        {`Cloud Defense is discovering Data Stores in the Account:
                      ${getAccountName()}. The Data Stores will be displayed below in
                      the order of discovery. We discover the Store Name, Type,
                      Location and other meta data.`}
                      </p>
                    )}
                    {/* <button type="button" class="btn btn-primary">
                    NOTIFY ME WHEN DONE
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class="row risk-card-container">
              {scoreCards.map((card, index) => (
                <Card
                  title={card.title}
                  curve={card.curve}
                  score={card.score}
                  arrow={card.arrow}
                  seperator={card.seperator}
                  scoreClass={card.scoreClass}
                  key={index}
                  selectedSeverity={severity}
                  severity={card.severity}
                  filterIssues={filterIssues}
                  label={card.label}
                  disabled={card.disabled}
                  length={scoreCards.length}
                />
              ))}
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default RiskCards;

import React, { memo, useEffect, useState } from "react";
import ExternalIcon from "../../../assets/img/icons/external.svg";
import acceptRisk from "../../../assets/img/icons/risk-accept.svg";
import restoreRisk from "../../../assets/img/icons/risk-accepted.svg";
import Bug from "../../../assets/img/icons/small/bug.svg";
import TaskYellow from "../../../assets/img/icons/small/task-yellow.svg";
import Wiz from "../../../assets/img/integrations/wiz2.png";
import { formatLine, getIssueIcon } from "../../../utils/styles";
import RiskAcceptModal from "../../modal/risk-accept-modal/RiskAcceptModal";
import "./TopPanelComponent.scss";
import EC2Image from "../../../assets/img/new-theme/attack-path-icons/aws-ec-2-image-circle.svg";
import DarkNet from "../../../assets/img/v1-icons/darknet-icon.svg";
import IssueInstanceModal from "../../modal/issue-instance-modal/IssueInstanceModal";
import CreateIssueModal from "../../modal/issue-modal/CreateIssue";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import CustomTooltip from "../../custom-tooltip/CustomTooltip";
import { useSelector } from "react-redux";
import { isJsonEmpty, numberFormatter } from "../../../utils/utils";

const TopPanelComponent = ({ props }) => {
  const [isRiskAcceptModal, setIsRiskAcceptModal] = useState();
  const [isIssueInstanceModal, setIsIssueInstanceModal] = useState();
  const [createIssueModal, setCreateIssueModal] = useState(false);
  const resolveFactor = useSelector(
    (state) => state?.dashboard?.issueUberScore?.resolveFactor
  );

  const formatResolveFactor = () => {
    return Number.isInteger(resolveFactor)
      ? resolveFactor
      : resolveFactor?.toFixed(2);
  };
  return (
    <div>
      {props.toggle ? (
        <div id="top-panel-main-container" className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-5">
              <div className="workday-block">
                <div className="row">
                  <div className="col col-sm-12">
                    <img
                      src={getIssueIcon(
                        props.selectedBar.type,
                        props.selectedBar.score
                      )}
                      className="datastore-logo ml-2 mb-2"
                    />

                    {props &&
                    props.selectedBar &&
                    props.selectedBar.name &&
                    props.selectedBar.name.toLowerCase().includes("wiz") ? (
                      <img src={Wiz} className="wiz-logo" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col col-sm-12">
                    <div className="d-flex align-items-start">
                      <div className="pl-3 w-100">
                        <div className="row">
                          <div
                            className="col col-lg-12 align-item-end col-sm-12 workday-heading"
                            title={props.selectedBar.name}
                          >
                            {formatLine(props.selectedBar.displayId, 42)}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Title:
                          </div>
                          <CustomTooltip
                            component={
                              <div className="col col-sm-8 workday-subheading-value">
                                {formatLine(props.selectedBar.title, 34)}
                              </div>
                            }
                            tooltipTitle={props.selectedBar.title}
                            className="sub-title-popup"
                          />
                        </div>
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Type:
                          </div>
                          <div className="col col-sm-8 workday-subheading-value">
                            {props.selectedBar.type}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Instance:
                          </div>
                          <CustomTooltip
                            component={
                              <div className="col col-sm-8 workday-subheading-value">
                                {props.assetId
                                  ? formatLine(
                                      props.assetId.substring(
                                        props.assetId.lastIndexOf(":") + 1
                                      ),
                                      36
                                    )
                                  : null}
                              </div>
                            }
                            tooltipTitle={props.assetId}
                            className="sub-title-popup"
                          />
                        </div>
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Severity:
                          </div>
                          <div className="col col-sm-8 workday-subheading-value">
                            {props.selectedBar.severity}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Account:
                          </div>
                          <div className="col col-sm-8 workday-subheading-value">
                            {props.selectedBar.cloudAccountId}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Discovered On:
                          </div>
                          <div className="col col-sm-8 workday-subheading-value">
                            {props.selectedBar.discoveredOn}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Last Scanned:
                          </div>
                          <div className="col col-sm-8 workday-subheading-value">
                            {props.selectedBar.lastScanned}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col col-sm-4 workday-subheading">
                            Resolve Factor:
                          </div>
                          <div className="col col-sm-8 workday-subheading-value">
                            {formatResolveFactor()}
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col-lg-3 col-md-3 col-sm-6">
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="card-circle" title="Ticket">
                                <img
                                  src={TaskYellow}
                                  className="mb-1 pointer"
                                  onClick={() => setCreateIssueModal(true)}
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center m-1 mb-0">
                              <p>
                                <b>
                                  {props.selectedBar.tickets
                                    ? props.selectedBar.tickets
                                    : 0}
                                </b>
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-3 col-sm-6">
                            <div className="d-flex align-items-center justify-content-center">
                              <div
                                className={
                                  props.selectedBar.instances &&
                                  props.selectedBar.instances.length > 0
                                    ? "card-circle cursor-pointer"
                                    : "card-circle  disable-button"
                                }
                                onClick={() => {
                                  props.selectedBar.instances &&
                                    props.selectedBar.instances.length > 0 &&
                                    setIsIssueInstanceModal(true);
                                }}
                              >
                                <img
                                  src={EC2Image}
                                  className="mb-1"
                                  title="Other Instances of same Issue"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center m-1 mb-0">
                              <p>
                                <b>
                                  {props.selectedBar.instances
                                    ? props.selectedBar.instances.length
                                    : 0}
                                </b>
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-lg-3 col-md-3 col-sm-6"
                            title="Estimated Darknet Value"
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="card-circle">
                                <img
                                  src={DarkNet}
                                  className="mb-1 "
                                  width="40px"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center m-1 mb-0">
                              <p>
                                <b className={` pe-auto`}>
                                  $
                                  {numberFormatter(
                                    props?.selectedBar?.issueDatastoreSaving ||
                                      0
                                  )}
                                </b>
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-3 col-sm-6">
                            <div
                              onClick={() => setIsRiskAcceptModal(true)}
                              className="d-flex align-items-center justify-content-center"
                            >
                              <div className="card-circle cursor-pointer">
                                <img
                                  src={
                                    props.selectedBar.risk_accepted
                                      ? restoreRisk
                                      : acceptRisk
                                  }
                                  className="mb-1 "
                                  width="40px"
                                  title={
                                    props.selectedBar.risk_accepted
                                      ? "Click to Restore Risk from this Issue"
                                      : "Click to Accept Risk from this Issue"
                                  }
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center m-1 mb-0">
                              <p>
                                <b
                                  className={`${
                                    props.selectedBar.risk_accepted
                                      ? "risk-title-accepted"
                                      : "risk-title-accept"
                                  } pe-auto`}
                                >
                                  {props.selectedBar.risk_accepted
                                    ? "Accepted"
                                    : "Accept"}
                                </b>
                              </p>
                            </div>
                          </div>{" "}
                          {isRiskAcceptModal ? (
                            <RiskAcceptModal
                              securityIssueInstanceId={
                                props.selectedBar.securityIssueInstanceId
                              }
                              securityIssueId={props.selectedBar.name}
                              instanceLocation={
                                props?.assetId
                                  ? formatLine(
                                      props?.assetId.substring(
                                        props?.assetId.lastIndexOf(":") + 1
                                      ),
                                      36
                                    )
                                  : null
                              }
                              displayId={props?.selectedBar?.idDisplay}
                              riskAccepted={props.selectedBar.risk_accepted}
                              allRiskAccepted={
                                props.selectedBar.accepted_all_risks
                              }
                              handleModal={() => setIsRiskAcceptModal(false)}
                              refreshIssues={props.refreshIssues}
                            />
                          ) : (
                            ""
                          )}
                          {isIssueInstanceModal ? (
                            <IssueInstanceModal
                              instances={props.selectedBar.instances}
                              handleModal={() => setIsIssueInstanceModal(false)}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-7 pl-md-0">
              <div className="sensitive-data-block p-2">
                <div className="row mt-1">
                  <div className="col col-sm-12 sensitive-data-heading">
                    TITLE
                  </div>
                </div>
                <div className="row">
                  <div className="col col-sm-12 title-style">
                    {props.selectedBar.title}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col col-sm-12 sensitive-data-heading">
                    DESCRIPTION
                  </div>
                </div>
                <div className="row vulns-data">
                  <div className="col col-sm-12">
                    {props.vulnerability.description}
                  </div>
                </div>
                {props?.vulnerability?.remediation ||
                props?.vulnerability?.externalUrl ? (
                  <div className="row mt-2">
                    <div className="col col-sm-12 sensitive-data-heading">
                      REMEDIATION
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row vulns-data">
                  {props?.vulnerability?.remediation &&
                    props?.vulnerability?.remediation !== null &&
                    props?.vulnerability?.remediation !== "None Provided" && (
                      <div className="col col-sm-12">
                        {props.vulnerability.remediation}
                      </div>
                    )}

                  {props?.vulnerability?.externalUrl &&
                    props?.vulnerability?.externalUrl !== null && (
                      <div className="col col-sm-12">
                        <a
                          href={props.vulnerability.externalUrl}
                          className={`text-info active-link`}
                          target={"_blank"}
                        >
                          <i className="icon fa fa-external-link "></i>
                          {props.vulnerability.externalUrl}
                        </a>
                      </div>
                    )}
                </div>
                {props?.vulnerability?.additionalInfo ? (
                  <div className="row mt-2">
                    <div className="col col-sm-12 sensitive-data-heading">
                      Additional Information
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row vulns-data">
                  {props?.vulnerability?.additionalInfo &&
                    props?.vulnerability?.additionalInfo !== null && (
                      <div className="col col-sm-12">
                        {props.vulnerability.additionalInfo}
                      </div>
                    )}
                </div>
                <div className="py-2"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="top-panel-2">
          {" "}
          <div id="top-panel-main-container" className="container-fluid px-2">
            <div className="row">
              <div className="col col-sm-1 col-lg-1">
                <div className="number-circle">99</div>{" "}
              </div>
              <div className="col col-sm-3 col-md-3 col-lg-3 text-info">
                <b>CVE-2021-44228</b>
              </div>
              <div className="col col-sm-3 col-md-3 col-lg-3 text-info">
                <b>Instance: 00737</b>
              </div>
              <div class="col align-self-center">
                {" "}
                <div class="float-right">
                  {" "}
                  <i class="icon fa fa-circle text-danger red-dot"></i> 9.8 -
                  Critical
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {createIssueModal ? (
        <CreateIssueModal
          handleModal={() => setCreateIssueModal(false)}
          issueId={props.selectedBar.idDisplay}
          instanceID={props.selectedBar.securityIssueInstanceId}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default memo(TopPanelComponent);

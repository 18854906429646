import { useState, useEffect } from "react";
import AddPolicyModal from "../modal/add-policy-modal/AddPolicyModal";
import {
  listPoliciesAsync,
  deletePolicyAsync,
} from "../../redux/slices/policySlice";
import { useDispatch, useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { LOADER_CONFIG } from "../../utils/Constants";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import DeleteConfirmationModal from "../modal/shared/DeleteConfirmationModal";
import CompliancePolicyModal from "../modal/compliance-policy-modal/CompliancePolicyModal";
import { getAllPolicies } from "../../redux/slices/policySlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { listDataStoreNames } from "../../redux/slices/dataStoreSlice";
import { useParams } from "react-router-dom";

const PolicySetting = ({}) => {
  const [isAddPolicyModal, setIsAddPolicyModal] = useState();
  const [isAddCompliancePolicyModal, setIsAddCompliancePolicyModal] = useState(
    false
  );
  const params = useParams();
  const [policyAction, setPolicyAction] = useState();
  const [compliancePolicyAction, setCompliancePolicyAction] = useState();
  const [alertingPolicies, setAlertingPolicies] = useState([]);
  const [compliancePolicies, setCompliancePolicies] = useState([]);
  const dispatch = useDispatch();
  const [selectedPolicy, setSelectedPolicy] = useState();
  const policies = useSelector((state) => state.policy.policies);
  const [loading, setLoading] = useState();
  const [isDelete, setIsDelete] = useState();
  const [isComplianceDelete, setIsComplianceDelete] = useState();
  const [policiesList, setPoliciesList] = useState([]);
  const [datastoreMappings, setDatastoreMappings] = useState({});
  const [selectedAccountIDs, setSelectedAccountIDs] = useState();
  const selectedPolicyItem = sessionStorage.getItem("policy");

  const getNames = (name) => {
    return datastoreMappings[name];
  };

  const getDatastoreNames = async (ids) => {
    let payload = {
      customerId: getCustomerId(),
      datastores: ids,
    };
    let res = await dispatch(listDataStoreNames(payload));
    if (res && res.payload) {
      setDatastoreMappings(res.payload);
    }
  };

  useEffect(() => {
    if (!compliancePolicies.length) return;
    let ids = [];
    for (let i of compliancePolicies) {
      for (let j of i.datastoreIds) {
        ids.push(j);
      }
    }
    getDatastoreNames(ids);
  }, [compliancePolicies]);

  const refreshAccounts = async () => {
    // await dispatch(listPoliciesAsync());
    getAllPolicieList();
  };
  const [showLoader, setShowLoader] = useState(false);

  const getAllPolicieList = async () => {
    setLoading(true);

    let res = await dispatch(listPoliciesAsync());
    if (res && res.payload && !res.payload.err) {
      if (res.payload && res.payload.length > 0) {
        let updatedCompliancePolicy = [];
        let updatedAlertingPolicy = [];
        res.payload.map((policy) => {
          if (policy.category == "alerting") {
            updatedAlertingPolicy.push(policy);
          }
          if (policy.category == "compliance") {
            updatedCompliancePolicy.push(policy);
          }
        });
        setAlertingPolicies(updatedAlertingPolicy);
        if (params?.policyID) {
          setCompliancePolicies(
            filterCompliancePolicies(updatedCompliancePolicy)
          );
        } else {
          setCompliancePolicies(updatedCompliancePolicy);
        }
      }
      // setAlertingPolicies(res.payload);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const filterCompliancePolicies = (updatedPolicies) => {
    let filteredPolicis = [];
    let policyRule = JSON.parse(selectedPolicyItem).policyRule;
    for (let index = 0; index < updatedPolicies.length; index++) {
      updatedPolicies[index]?.policies.map((policyItem) => {
        if (policyItem.rule === policyRule) {
          filteredPolicis.push(updatedPolicies[index]);
        }
      });
    }
    return filteredPolicis;
  };
  const deletePolicy = async () => {
    setShowLoader(true);
    const resp = await dispatch(deletePolicyAsync(selectedPolicy._id));
    if (resp && resp.payload) {
      setShowLoader(false);
      setIsDelete(false);
      setIsComplianceDelete(false);

      Toaster(TOASTER_TYPES.SUCCESS, "Policy deleted successfully.");
      refreshAccounts();
    } else {
      setIsDelete(false);
      setIsComplianceDelete(false);

      setShowLoader(false);
      Toaster(TOASTER_TYPES.ERROR, "Failed to delete policy.");
    }
  };

  const refreshPolicyDetails = () => {
    getAllPolicieList();
  };

  useEffect(() => {
    getAllPolicieList();
  }, []);

  const getTriggerConditionValue = (value, items) => {
    if (items) {
      let actions = Object.keys(items);
      if (actions.includes(value) === true) {
        return <i class="ri-checkbox-circle-fill"></i>;
      } else {
        return <i class="ri-close-circle-fill"></i>;
      }
    } else {
      return <i class="ri-close-circle-fill"></i>;
    }
  };

  const getAlertingAction = (items) => {
    if (items) {
      let actions = Object.keys(items);
      if (actions.length > 0) {
        let action = actions[0];
        return items[action];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (selectedAccountIDs) {
      getPredefinesPolicieList();
    }
  }, [selectedAccountIDs]);

  const getPredefinesPolicieList = async () => {
    setLoading(true);
    let payload = {
      customerId: getCustomerId(),
      cloudAccountId: selectedAccountIDs ? selectedAccountIDs : "",
    };
    let res = await dispatch(getAllPolicies(payload));
    let updatedPolicies = [];
    if (res && res.payload && !res.payload.err) {
      res.payload.map((policy) => {
        updatedPolicies.push({
          value: policy.rule,
          label: policy.description,
        });
      });
      setPoliciesList(updatedPolicies);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPredefinesPolicieList();
  }, []);

  return (
    <>
      <div className="policy-settings ml-0">
        <div className="row m-0 justify-content-between align-items-center heading-section">
          <div className="col col-auto p-2">
            <h5>Policy Settings</h5>
          </div>
        </div>
        <BlockUi
          tag="div"
          blocking={loading}
          loader={
            <Loader
              active
              type={LOADER_CONFIG.type}
              color={LOADER_CONFIG.color}
            />
          }
        >
          <div className="row m-0 justify-content-between align-items-center heading-section">
            <div className="col col-auto p-2">
              <h6>Alerting Policy</h6>
            </div>
            <div className="col col-auto align-items-center p-2 ">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setIsAddPolicyModal(true);
                  setPolicyAction("add");
                }}
              >
                <img
                  alt=""
                  src="static/img/add-account.svg"
                  className="add-account-svg"
                />
                Add Alerting Policy
              </button>
              {isAddPolicyModal && policyAction === "add" && (
                <AddPolicyModal
                  refreshPolicyDetails={refreshPolicyDetails}
                  refreshAccounts={refreshAccounts}
                  policyAction={policyAction}
                  handleModal={() => setIsAddPolicyModal(false)}
                  policiesList={policiesList}
                />
              )}
            </div>
          </div>
          <div className="mt-3 table-responsive">
            <table className="table table-border-less">
              <thead>
                <tr className="vuls-row">
                  <th>Policy Name</th>
                  <th>Description</th>
                  <th>Account</th>
                  <th>Risk Upgrade to Critical</th>
                  <th>Risk Upgrade To High</th>
                  <th>Direct Anomaly On Datastore</th>
                  <th>Indirect Anomaly Via Cloud Environment</th>
                  <th>Alerting Action</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {alertingPolicies &&
                  alertingPolicies.map(function (item, idx) {
                    return (
                      <tr key={idx} className="vuls-row">
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                        <td>
                          <div className="row py-2 chip-container">
                            {item.cloudAccountIds &&
                              item.cloudAccountIds.map((tag, i) => (
                                <div className="col workday-subheading" key={i}>
                                  <div className="chip m-1" title={tag}>
                                    <span className="mr-2">{tag}</span>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </td>
                        <td className="text-center">
                          {getTriggerConditionValue(
                            "datastore_risk_critical",
                            item.triggerAction
                          )}
                        </td>
                        <td className="text-center">
                          {getTriggerConditionValue(
                            "datastore_risk_high",
                            item.triggerAction
                          )}
                        </td>
                        <td className="text-center">
                          {getTriggerConditionValue(
                            "direct_anomaly",
                            item.triggerAction
                          )}
                        </td>
                        <td className="text-center">
                          {getTriggerConditionValue(
                            "indirect_anomaly",
                            item.triggerAction
                          )}
                        </td>
                        <td>{getAlertingAction(item.triggerAction)}</td>
                        <td className="action-buttons">
                          <i
                            className="ri-pencil-fill mr-2 cursor-pointer"
                            title="Edit Policy"
                            onClick={() => {
                              setIsAddPolicyModal(true);
                              setPolicyAction("edit");
                              setSelectedPolicy(item);
                            }}
                          ></i>
                          <i
                            className="ri-delete-bin-2-fill"
                            title="Delete Policy"
                            onClick={() => {
                              setIsDelete(true);
                              setSelectedPolicy(item);
                            }}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                {isAddPolicyModal && policyAction === "edit" && (
                  <AddPolicyModal
                    refreshAccounts={refreshAccounts}
                    policyAction={policyAction}
                    policyItem={selectedPolicy}
                    handleModal={() => setIsAddPolicyModal(false)}
                    policiesList={policiesList}
                  />
                )}
                {isDelete && (
                  <DeleteConfirmationModal
                    handleModal={() => setIsDelete(false)}
                    deleteHandle={deletePolicy}
                    showLoader={showLoader}
                    modalData={{
                      title: "Delete Policy",
                      message: "Are you sure, you want to delete this policy?",
                    }}
                  />
                )}
              </tbody>
            </table>
          </div>

          <div className="row m-0 justify-content-between align-items-center heading-section">
            <div className="col col-auto p-2">
              <h6>Compliance Policy</h6>
            </div>
            <div className="col col-auto align-items-center p-2 ">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setIsAddCompliancePolicyModal(true);
                  setCompliancePolicyAction("add");
                }}
              >
                <img
                  alt=""
                  src="static/img/add-account.svg"
                  className="add-account-svg"
                />
                Add Compliance Policy
              </button>

              {isAddPolicyModal && policyAction === "add" && (
                <AddPolicyModal
                  refreshAccounts={refreshAccounts}
                  policyAction={policyAction}
                  handleModal={() => setIsAddPolicyModal(false)}
                  policiesList={policiesList}
                />
              )}
            </div>
          </div>
          <div className="mt-3 table-responsive">
            <table className="table table-border-less">
              <thead>
                <tr className="vuls-row">
                  <th>Policy Name</th>
                  <th>Account</th>
                  <th>Data Store</th>
                  {/* <th>Sensitivity Tags</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {compliancePolicies &&
                  compliancePolicies.map((policy) => {
                    return (
                      <tr className="vuls-row">
                        <td>{policy.name}</td>
                        <td>
                          <div className="row py-2 chip-container">
                            {policy.cloudAccountIds &&
                              policy.cloudAccountIds.map((tag, i) => (
                                <div
                                  className="col col-sm-6 workday-subheading"
                                  key={i}
                                >
                                  <div className="chip m-1" title={tag}>
                                    <span className="mr-2">{tag}</span>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </td>

                        <td>
                          <div className="row py-2 chip-container">
                            {policy.datastoreIds &&
                              policy.datastoreIds.map((tag, i) => (
                                <div
                                  className="col col-sm-6 workday-subheading"
                                  key={i}
                                >
                                  <div className="chip m-1" title={tag}>
                                    <span className="mr-2">
                                      {getNames(tag)}
                                    </span>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </td>
                        {/* <td>
                          <div className="row py-2 chip-container">
                            {policy.sensitivityTags &&
                              policy.sensitivityTags.map((tag, i) => (
                                <div
                                  className="col col-sm-6 workday-subheading"
                                  key={i}
                                >
                                  <div className="chip m-1" title={tag}>
                                    <span className="mr-2">{tag}</span>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </td> */}
                        <td className="action-buttons">
                          <i
                            className="ri-pencil-fill mr-2 cursor-pointer"
                            title="Edit Policy"
                            onClick={() => {
                              setIsAddCompliancePolicyModal(true);
                              setCompliancePolicyAction("edit");
                              setSelectedPolicy(policy);
                            }}
                          ></i>
                          <i
                            className="ri-delete-bin-2-fill"
                            title="Delete Policy"
                            onClick={() => {
                              setIsComplianceDelete(true);
                              setSelectedPolicy(policy);
                            }}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}

                {isAddCompliancePolicyModal && (
                  <CompliancePolicyModal
                    setSelectedAccountIDs={setSelectedAccountIDs}
                    refreshAccounts={refreshAccounts}
                    policyAction={compliancePolicyAction}
                    policyItem={selectedPolicy}
                    handleModal={() => setIsAddCompliancePolicyModal(false)}
                    policiesList={policiesList}
                    setDatastoreMappings={setDatastoreMappings}
                  />
                )}
                {isComplianceDelete && (
                  <DeleteConfirmationModal
                    handleModal={() => setIsComplianceDelete(false)}
                    deleteHandle={deletePolicy}
                    showLoader={showLoader}
                    modalData={{
                      title: "Delete Policy",
                      message: "Are you sure, you want to delete this policy?",
                    }}
                  />
                )}
              </tbody>
            </table>
          </div>
        </BlockUi>
        {/* <div className="mx-4 mt-4">
          <div>
            <b className="automation-policy-title">
              
              Custom Automation Policies
            </b>
          </div>
          <hr />
          <div className="mt-3">
            <table className="automation-policy-table">
              <thead>
                <tr>
                  <th>Policy Name</th>
                  <th>Description</th>
                  <th>Account</th>
                  <th>Issues</th>
                  <th>Resources</th>

                  <th>Alerting Action</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {automationPolicies.map(function (item, idx) {
                  return (
                    <tr key={idx}>
                      <td>{item.policyName}</td>
                      <td>{item.description}</td>
                      <td>{item.accounts}</td>
                      <td>{item.issues}</td>
                      <td>{item.resources}</td>
                      <td>{item.alertingAction}</td>
                      <td className="action-buttons">
                        <i
                          className="ri-pencil-fill mr-2 cursor-pointer"
                          title="Edit Policy"
                        ></i>
                        <i
                          className="ri-delete-bin-2-fill"
                          title="Delete Policy"
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default PolicySetting;

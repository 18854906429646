export const trainingDataList = [
  {
    FileName: "training.json",
    Location: "s3://sagemaker-us-east-1-099873896856/training.json",
    Source: "Configured",
    sensitive_labels: "SSN, PII, ADDRESS",
    size: "4.16 GB",
    type: "s3",
    training_date: "2023-05-10T04:32:36.491000",
    policy_violation: true,
    account: "099873896856",
    count: 4,
  },
  {
    FileName: "training_2.json",
    Location: "s3://sagemaker-us-east-1-099873896856/training_2.json",
    Source: "Configured",
    sensitive_labels: "SSN, PII, ADDRESS",
    size: "2.45 GB",
    type: "s3",
    training_date: "2023-05-15T04:32:36.491000",
    policy_violation: true,
    account: "099873896856",
    count: 3,
  },
  {
    FileName: "training_3.json",
    Location: "s3://sagemaker-us-east-1-099873896856/training_3.json",
    Source: "Configured",
    sensitive_labels: "SSN, PII, ADDRESS",
    size: "6.34 GB",
    type: "s3",
    training_date: "2023-06-10T04:32:36.491000",
    policy_violation: true,
    account: "099873896856",
    count: 3,
  },
  // {
  //   FileName: "training_4.json",
  //   Location: "s3://sagemaker-us-east-1-099873896856/training_4.json",
  //   Source: "Live Access",
  //   sensitive_labels: "SSN, PII, ADDRESS",
  //   size: "5.3 GB",
  //   type: "s3",
  //   training_date: "2023-06-09T04:32:36.491000", account: "099873896856",
  // },
  // {
  //   FileName: "training_5.json",
  //   Location: "s3://sagemaker-us-east-1-099873896856/training_5.json",
  //   Source: "Live Access",
  //   sensitive_labels: "SSN, PII, ADDRESS",
  //   size: "1.12 GB",
  //   type: "s3",
  //   training_date: "2023-07-04T04:32:36.491000", account: "099873896856",
  // },
  {
    FileName: "training_6.json",
    Location: "s3://sagemaker-us-east-1-099873896856/training_6.json",
    Source: "Access Path",
    sensitive_labels: "SSN, PII, ADDRESS",
    size: "2.16 GB",
    type: "s3",
    training_date: "2023-07-05T04:32:36.491000",
    account: "099873896856",
    count: 1,
  },
];

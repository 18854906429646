import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import integrationIcon from "../../../assets/img/icons/settings/integration.svg";
import { updateWizIntegrationAsync } from "../../../redux/slices/integrationSlice";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import "./IntegrationModal.scss";
import { getUserId } from "../../../utils/SessionHelper";

// Functional component for Add Integration.
const UpdateWizIntegrationModal = ({
  handleModal,
  refreshIntegrations,
  card,
  edit,
}) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);
  const [masked, setMasked] = useState(true);

  // Formik
  const formik = useFormik({
    initialValues: {
      client_id: "",
      client_secret: "",
      endpoint_url: "",
      auth_url: "",
    },
    validationSchema: Yup.object().shape({
      client_id: Yup.string().required("Please enter Client ID."),
      client_secret: Yup.string().required("Please enter client secret."),
      endpoint_url: Yup.string().required("Please elient endpoint url."),
      auth_url: Yup.string().required("Please enter Authentication URL."),
    }),
    onSubmit: (values) => {
      updateIntegration({ ...values });
    },
  });

  useEffect(() => {
    if (card) {
      formik.setFieldValue("client_id", card.client_id);
      formik.setFieldValue("client_secret", card.client_secret);
      formik.setFieldValue("endpoint_url", card.endpoint_url);
      formik.setFieldValue("auth_url", card.auth_url);
    }
  }, [card]);

  const handleCancel = () => {
    handleModal(false);
  };

  const updateIntegration = async (values) => {
    setShowLoader(true);
    setConnectionFailed(false);
    const resp = await dispatch(
      updateWizIntegrationAsync({
        endpoint_url: values.endpoint_url,
        client_id: values.client_id,
        client_secret: values.client_secret,
        id: card._id,
        auth_url: values.auth_url,
        userId: getUserId(),
      })
    );
    if (resp && resp.payload) {
      Toaster(TOASTER_TYPES.SUCCESS, "Integration updated successfully.");
      setShowLoader(false);
      refreshIntegrations();
      handleModal(false);
    } else {
      // Toaster(TOASTER_TYPES.ERROR, "Failed to add Integration.");
      setShowLoader(false);
      setConnectionFailed(true);
    }
  };

  return (
    <div id="integration-modal">
      <div
        className="modal fade show"
        id="integrationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="integrationModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <span>Update Wiz Integration</span>
              </div>
              <div className="modal-body">
                <label className="form-field-name ">
                  Authentication Endpoint{" "}
                  <span className="mandatory-icon">*</span>
                </label>

                <div className="row">
                  <div className="col col-lg-12">
                    <input
                      type="text"
                      id="name"
                      placeholder="Auth URL"
                      name="name"
                      maxLength={50}
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("auth_url", e.target.value);
                      }}
                      value={formik.values.auth_url}
                      onBlur={formik.handleBlur}
                      disabled={!edit}
                    />

                    {formik.errors.auth_url && formik.touched.auth_url && (
                      <div className="error-message">
                        {formik.errors.auth_url}
                      </div>
                    )}
                  </div>
                </div>

                <label className="form-field-name ">
                  API Endpoint <span className="mandatory-icon">*</span>
                </label>

                <div className="row">
                  <div className="col col-lg-12">
                    <input
                      type="text"
                      id="name"
                      placeholder="API Endpoint URL"
                      name="name"
                      maxLength={50}
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("endpoint_url", e.target.value);
                      }}
                      value={formik.values.endpoint_url}
                      onBlur={formik.handleBlur}
                      disabled={!edit}
                    />

                    {formik.errors.endpoint_url &&
                      formik.touched.endpoint_url && (
                        <div className="error-message">
                          {formik.errors.endpoint_url}
                        </div>
                      )}
                  </div>
                </div>

                <label className="form-field-name ">
                  Client ID <span className="mandatory-icon">*</span>
                </label>

                <div className="row">
                  <div className="col col-lg-12">
                    <input
                      type="text"
                      id="name"
                      placeholder="Enter Client ID"
                      name="name"
                      maxLength={50}
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("client_id", e.target.value);
                      }}
                      value={formik.values.client_id}
                      onBlur={formik.handleBlur}
                      disabled={!edit}
                    />
                    {formik.errors.client_id && formik.touched.client_id && (
                      <div className="error-message">
                        {formik.errors.client_id}
                      </div>
                    )}
                  </div>
                </div>

                <label className="form-field-name mt-3">
                  Client Secret <span className="mandatory-icon">*</span>
                  <i
                    class={masked ? "las la-eye-slash ml-2" : "las la-eye ml-2"}
                    onClick={() => setMasked(!masked)}
                  ></i>
                </label>
                <div className="row">
                  <div className="col col-lg-12">
                    <input
                      type={masked ? "password" : "text"}
                      id="name"
                      placeholder="Enter Client Secret"
                      name="name"
                      maxLength={50}
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("client_secret", e.target.value);
                      }}
                      value={formik.values.client_secret}
                      onBlur={formik.handleBlur}
                      disabled={!edit}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <div className="error-message">{formik.errors.name}</div>
                    )}
                  </div>
                </div>

                {connectionFailed && (
                  <div className="row mt-2">
                    <div className="col-9 text-danger">
                      Invalid credentials. Please check and try again.
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Submit
                </button>
                <span className="separator mx-3"></span>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateWizIntegrationModal;

import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { useHistory, useParams } from "react-router-dom";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // for ES6 modules
import { getIssuesApi, getIssuesApiV2 } from "../../apis";
import closeIcon from "../../assets/img/icons/close-icon.svg";
import NoDataContainer from "../../components/cards/no-data";
import Pagination from "../../components/pagination/Pagination";
import Cards from "../../components/vulnerabilities/cards";
import Table from "../../components/vulnerabilities/table";
import {
  LOADER_CONFIG,
  REACT_APP_SECURITYISSUES_API,
} from "../../utils/Constants";
import { addIndex } from "../../utils/dataHandler";
import {
  getCustomerId,
  getSelectedIssuesAccount,
  setSelectedIssuesAccount,
} from "../../utils/SessionHelper";
import Select, { components } from "react-select";
import { ReactComponent as FilterIcon } from "../../assets/img/new-theme/filter.svg";

import "../Datastore/datastoreView.scss";
const Datastore = ({ setPath, filterIssues, selectedAccount }) => {
  const limit = 10;
  const defaultAccount = getSelectedIssuesAccount() || {
    value: "all",
    label: "All",
  };
  const [subFilter, setSubFilter] = useState({
    value: "Active",
    label: "Active",
  });
  const [subFiltersList, setSubFiltersList] = useState([
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Risk Accepted",
      label: "Risk Accepted",
    },
    {
      value: "Resolved",
      label: "Resolved",
    },
  ]);
  const params = useParams();
  const [inputText, setInputText] = useState("");
  const [currentPosts, setCurrentPosts] = useState([]);
  const [severity, setSeverity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("cdIssueRiskScore");
  const [sortType, setSortType] = useState("desc");
  const routeAssetId = params.assetId?.replaceAll("_", "/");
  const cloudEnvClass = params.cloudEnvClass;

  const policyViolationId = params.policyViolationId;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);

  const [totalIssuesCount, setTotalIssuesCount] = useState(0);
  const [isRiskAccepted, setIsRiskAccepted] = useState(false);
  const [assetId, setAssetId] = useState();
  const history = useHistory();

  useEffect(() => {
    document.title = "Cloud Defense - Vulnerabilities";
    setPath(window.location.pathname);
  }, []);

  const getIssues = async (page_ = 0) => {
    setLoading(true);
    const cloudAccountId =
      selectedAccount.value === "all" ? null : selectedAccount.value;
    let payload = {
      customerId: getCustomerId(),
      cloudAccountId: cloudAccountId,
      page: page_ ? page_ : page,
      limit: limit,
      ordering: sortField,
      severity:
        !severity || severity.includes("TOTAL ISSUES")
          ? null
          : severity.replaceAll(" ", "_"),
      policyViolationId: policyViolationId ? policyViolationId : null,
      // risk_accepted: isRiskAccepted,
    };

    const search_ =
      search && search.includes(":") ? search.split(":")[1] : search;
    const keyword_ =
      search && search.includes(":") ? search.split(":")[0] : null;

    if (REACT_APP_SECURITYISSUES_API === "OPENSEARCH") {
      payload = {
        customerId: getCustomerId(),
        q: search_,
        dataset: "issues",
        exact: false,
        page: page_ ? page_ : page,
        limit: limit,
        ordering: sortField,
        sortType: sortType,
        cloudAccountId: cloudAccountId,
        severity:
          !severity || severity.includes("TOTAL ISSUES")
            ? null
            : severity.replaceAll(" ", "_"),
        risk_accepted: isRiskAccepted,
        policyViolationId: policyViolationId ? policyViolationId : null,
        cloudEnvClass: cloudEnvClass ? cloudEnvClass : null,
      };

      if (keyword_) {
        payload.keyword = keyword_;
      }
    }
    if (routeAssetId) {
      setAssetId(routeAssetId.replaceAll("_", "/"));
      payload.assetId = routeAssetId.replaceAll("_", "/");
    } else {
      setAssetId("undefine");
    }
    if (subFilter.value === "Active") {
      payload.risk_accepted = false;
    }
    if (subFilter.value === "Risk Accepted") {
      payload.risk_accepted = true;
    }
    if (subFilter.value === "Resolved") {
      payload.status = "resolved";
    }
    let res =
      REACT_APP_SECURITYISSUES_API === "OPENSEARCH"
        ? await getIssuesApiV2(payload)
        : await getIssuesApi(payload);

    if (res.err || !res.data) {
      setLoading(false);
      return;
    }

    setTotalIssuesCount(res.count);
    res = addIndex(res.data);
    setCurrentPosts(res);
    setLoading(false);
  };

  const handleRiskToggle = () => {
    setIsRiskAccepted(!isRiskAccepted);
  };

  const sortData = (field) => {
    if (field === sortField && sortType === "asc") {
      setSortType("desc");
    } else if (field === sortField && sortType === "desc") {
      setSortType("asc");
    } else {
      setSortType("desc");
    }
    setSortField(field);
    setPage(1);
  };

  useEffect(() => {
    setSelectedIssuesAccount(selectedAccount);
  }, [selectedAccount]);

  useEffect(() => {
    getIssues();
  }, [
    page,
    isRiskAccepted,
    subFilter,
    selectedAccount,
    sortField,
    severity,
    sortType,
  ]);

  useEffect(() => {
    sessionStorage.setItem("page", page);
  }, [page]);

  const issueList = () => {
    history.push("/vulnerabilities");
  };

  const makeSearch = (ev) => {
    ev.preventDefault();
    setPage(1);
    getIssues(1);
  };

  const CaretDownIcon = () => {
    return <FilterIcon />;
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const setFilterValue = (value) => {
    setSubFilter(value);
    if (value.value === "Risk Accepted") {
      setIsRiskAccepted(true);
    } else {
      setIsRiskAccepted(false);
    }
  };

  return (
    <div id="issues">
      <Cards
        filterIssues={(type) => {
          setPage(1);
          setSeverity(type);
        }}
        cloudEnvClass={cloudEnvClass}
        selectedAccount={selectedAccount}
        inputText={inputText}
        setInputText={setInputText}
      />

      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }
      >
        <div id="issues">
          <div className="table-main">
            <div class="row">
              <div className="col col-xl-4 align-self-start">
                <form onSubmit={makeSearch}>
                  <div class="form-group has-search">
                    <i class="ri-search-line form-control-feedback"></i>
                    <input
                      type="text"
                      className="form-control ml-3"
                      placeholder="Search.."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-end">
                <div className="d-flex">
                  {assetId === "undefine" ? (
                    ""
                  ) : (
                    <span className="asset-id-tag">
                      {assetId}
                      <img
                        className="close-icon"
                        src={closeIcon}
                        title="Close"
                        onClick={() => issueList()}
                      />
                    </span>
                  )}
                </div>
                <div className="d-flex datastore-details-issues">
                  <Select
                    isRtl
                    options={subFiltersList}
                    classNamePrefix="accounts-dropdown"
                    placeholder="Filters"
                    defaultValue={
                      subFiltersList.length > 0 && subFiltersList[0]
                    }
                    components={{ DropdownIndicator }}
                    // value={
                    //   accountList.find(
                    //     (element) => element.value === selectedAccount.value
                    //   )
                    //     ? selectedAccount
                    //     : accountList[0]
                    // }
                    onChange={(value) => setFilterValue(value)}
                    isSearchable={false}
                    className="accounts-dropdown"
                  />
                  {/* <label
                      htmlFor="risk-accept-toggle"
                      className="toggle-label mr-2"
                    >
                      Risk Accepted
                    </label>
                    <Toggle
                      defaultChecked={isRiskAccepted}
                      onChange={handleRiskToggle}
                      className="risk-accept-toggle"
                      icons={false}
                      id="risk-accept-toggle"
                    /> */}
                </div>
                <div className="d-flex">
                  <Pagination
                    page={page}
                    setPage={(val) => setPage(val)}
                    limit={limit}
                    totalCount={totalIssuesCount}
                  />
                </div>
              </div>
            </div>

            <>
              {currentPosts.length ? (
                <Table
                  severity={severity}
                  currentPosts={currentPosts}
                  sortData={sortData}
                  isRiskAccepted={isRiskAccepted}
                  refreshIssues={getIssues}
                  subFilter={subFilter}
                />
              ) : !loading ? (
                <NoDataContainer message={"No Issues Available"} />
              ) : (
                ""
              )}
            </>
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

export default Datastore;

import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { addWizIntegrationAsync } from "../../../redux/slices/integrationSlice";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import "./IntegrationModal.scss";
import { getUserId } from "../../../utils/SessionHelper";

const WizIntegrationModal = ({ handleModal, refreshIntegrations }) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);
  const [masked, setMasked] = useState(true);

  // Formik
  const formik = useFormik({
    initialValues: {
      client_id: "",
      client_secret: "",
      endpoint_url: "",
      auth_url: "https://auth.app.wiz.io/oauth/token",
      userId: getUserId(),
    },
    validationSchema: Yup.object().shape({
      endpoint_url: Yup.string().required("Please enter Endpoint URL."),
      client_id: Yup.string().required("Please enter Client Id."),
      client_secret: Yup.string().required("Please enter Client Secret."),
      auth_url: Yup.string().required("Please enter Authentication URL."),
    }),
    onSubmit: (values) => {
      addIntegration({ ...values });
    },
  });

  const handleCancel = () => {
    handleModal(false);
  };

  const addIntegration = async (values) => {
    setShowLoader(true);
    setConnectionFailed(false);
    const resp = await dispatch(
      addWizIntegrationAsync({
        endpoint_url: values.endpoint_url,
        client_id: values.client_id,
        client_secret: values.client_secret,
        userId: values.userId,
        auth_url: values.auth_url,
      })
    );

    if (resp && resp.payload && resp.payload.msg) {
      Toaster(TOASTER_TYPES.SUCCESS, resp.payload.msg);
      setShowLoader(false);
      refreshIntegrations();
      handleModal(false);
    } else {
      if (!resp.payload) {
        Toaster(
          TOASTER_TYPES.ERROR,
          "Invalid credentials. Please check and try again."
        );
        setShowLoader(false);
        setConnectionFailed(true);
      } else {
        Toaster(TOASTER_TYPES.ERROR, resp.payload.err.message);
        setShowLoader(false);
        setConnectionFailed(true);
      }
    }
  };

  return (
    <div id="integration-modal">
      <div
        className="modal fade show"
        id="integrationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="integrationModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <span>Add Wiz Integration</span>
              </div>
              <div className="modal-body">
                <label className="form-field-name">
                  Authentication Endpoint{" "}
                  <span className="mandatory-icon">*</span>
                </label>
                <div className="row">
                  <div className="col col-lg-12">
                    <input
                      type="text"
                      id="Name"
                      placeholder="Auth URL"
                      name="Name"
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("auth_url", e.target.value);
                      }}
                      value={formik.values.auth_url}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.auth_url && formik.touched.auth_url && (
                      <div className="error-message">
                        {formik.errors.auth_url}
                      </div>
                    )}
                  </div>
                </div>

                <label className="form-field-name">
                  API Endpoint <span className="mandatory-icon">*</span>
                </label>
                <div className="row">
                  <div className="col col-lg-12">
                    <input
                      type="text"
                      id="Name"
                      placeholder="API Endpoint URL"
                      name="Name"
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("endpoint_url", e.target.value);
                      }}
                      value={formik.values.endpoint_url}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.endpoint_url &&
                      formik.touched.endpoint_url && (
                        <div className="error-message">
                          {formik.errors.endpoint_url}
                        </div>
                      )}
                  </div>
                </div>

                <label className="form-field-name ">
                  Client ID <span className="mandatory-icon">*</span>
                </label>
                <div className="row">
                  <div className="col col-lg-12">
                    <input
                      type="text"
                      id="Name"
                      placeholder="Client ID"
                      name="Name"
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("client_id", e.target.value);
                      }}
                      value={formik.values.client_id}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.client_id && formik.touched.client_id && (
                      <div className="error-message">
                        {formik.errors.client_id}
                      </div>
                    )}
                  </div>
                </div>

                <label className="form-field-name ">
                  Client Secret <span className="mandatory-icon">*</span>
                  <i
                    class={masked ? "las la-eye-slash ml-2" : "las la-eye ml-2"}
                    onClick={() => setMasked(!masked)}
                  ></i>
                </label>
                <div className="row mt-2">
                  <div className="col col-lg-12">
                    <input
                      type={masked ? "password" : "text"}
                      id="Token"
                      placeholder="Client Secret"
                      name="Token"
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("client_secret", e.target.value);
                      }}
                      value={formik.values.client_secret}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.client_secret &&
                      formik.touched.client_secret && (
                        <div className="error-message">
                          {formik.errors.client_secret}
                        </div>
                      )}
                  </div>
                </div>

                {connectionFailed && (
                  <div className="row mt-2">
                    <div className="col-9 text-danger">
                      Invalid credentials. Please check and try again.
                    </div>
                  </div>
                )}

                <div className="mt-4">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={!formik.isValid}
                  >
                    Add Integration
                  </button>
                  <span className="separator mx-3"></span>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WizIntegrationModal;

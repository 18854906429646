import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { regions } from "./aws_regions.js";
import moment from "moment";
import "./WorldMap.jsx";
import logo from "../../assets/img/logo.svg";
import { getNodeIconByType } from "../../components/charts/d3-flow-chart/flowChartHelper";
import AffectedDatastoresAndIssuesModal from "../modal/affected-datastore-and-issue-modal/AffectedDatastoresAndIssuesModal.jsx";
import { getDatastoreIcon } from "../../utils/styles.js";

am4core.addLicense("ch-custom-attribution");

const WorldMap = ({
  selectedBar,
  datastoreSources,
  assetId,
  datastoreId,
  selectedTab,
  isUSOnlyAccess,
  rowIndex,
}) => {
  const [selectedDatastoreSource, setSelectedDatastoreSource] = useState();

  const [markerClicked, setMarkerClicked] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);

  const operationsInfo = (operations) => {
    // let operation = "<table><tr><td></td><td></td><td>Errors</td></tr>";

    // operations.map((item) => {
    //   operation += "<tbody><tr>";
    //   operation += `<td>${item.operation}(${item.count})</td> ${
    //     item?.errorCodes.length > 0 ? "<td>-<td>" : ""
    //   }`;
    //   item?.errorCodes?.map((error, step) => {
    //     operation += `<td>${error.code}(${error.count})</td>${
    //       step !== item.errorCodes.length - 1 ? ", " : ""
    //     }`;
    //   });
    //   operation += "</tr>";
    // });
    // operation += "</tbody></table>";

    let operation = "<div>";

    operations.map((item, index) => {
      operation += `<div class="d-flex">`;
      operation += `<div>${item.operation}(${item.count})</div> ${
        item?.errorCodes.length > 0
          ? "<div>&nbsp;&nbsp;-&nbsp;&nbsp;</div>" +
            (index === 0
              ? '<span class="node-title" style="margin-top: -25px">Errors<br/></span>'
              : "")
          : ""
      }`;
      item?.errorCodes?.map((error, step) => {
        operation += `<div style="${
          index === 0 ? "margin-left: -40px" : ""
        }"> ${error.code}(${error.count})</div>${
          step !== item.errorCodes.length - 1 ? ", " : ""
        }`;
      });
      operation += "</div>";
    });
    operation += "<div>";

    // operations.map((item, index) => {
    //   operation += `${item.operation}(${item.count})${
    //     index !== operations.length - 1 ? ", " : ""
    //   }`;
    // });
    console.log("Operation: ", operation);
    return operation;
  };

  const loadChart = () => {
    let datastoreNode = [];
    let updatedDatastoreSources = [];
    let updatedDatastoreSourceIds = [];
    let selectedDestinations = [];

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create("world-map", am4maps.MapChart);
    chart.fill = am4core.color("#ffee00");
    let interfaceColors = new am4core.InterfaceColorSet();

    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Mercator();
    // Export

    const checkComplianceViolationOnLiveness = (source) => {
      return (
        source &&
        source.region !== "North America" &&
        !source.region?.toLowerCase()?.includes("us") &&
        isUSOnlyAccess
      );
    };

    const getLatitudeAndLongitude = (value, region) => {
      if (value === "latitude") {
        let obj = regions.find((r) => r.code === region);
        return obj?.latitude || regions[0].latitude;
      }
      if (value === "longitude") {
        let obj = regions.find((r) => r.code === region);
        return obj?.longitude || regions[0].longitude;
      }
    };

    const setDatastoreSources = () => {
      let datasourceId = 0;

      datastoreSources?.ten_minutes?.sources?.map((sources) => {
        sources?.sourceIdentity?.sourceIPAddressLocations?.map((source) => {
          datasourceId = datasourceId + 1;
          let latitude = source?.location?.coordinates[0];
          let longitude = source?.location?.coordinates[1];
          updatedDatastoreSourceIds.push(datasourceId.toString());

          updatedDatastoreSources.push({
            id: datasourceId.toString(),
            userName: sources.sourceIdentity?.userName,
            sourceType: sources.sourceIdentity?.sourceType,
            title: source.city,
            sourceIpAddress: source.sourceIPAddress,
            latitude: latitude
              ? latitude
              : getLatitudeAndLongitude("latitude", source.region),
            longitude: longitude
              ? longitude
              : getLatitudeAndLongitude("longitude", source.region),
            color: "#000",
            name: source.sourceIPAddress,
            radius: 7,
            scale: 0.7,
            isComplianceViolation: checkComplianceViolationOnLiveness(source),
            href: getNodeIconByType(sources?.sourceIdentity?.sourceType),
            datastore_node: false,
            type: sources.sourceIdentity?.sourceType,
            is_primary: false,
            CD_Asset_Id: source.sourceAsset || undefined,
            eks_enabled: false,
            tooltip_content: {
              name: sources.sourceIdentity?.userName,
              type: sources.sourceIdentity?.sourceType || undefined,
              country: source.country || undefined,
              state: source.state || undefined,
              city: source.city || undefined,
              region: source.region || undefined,
              source_IP_Address: source.sourceIPAddress,
              id: source.sourceAsset || undefined,
              operations: operationsInfo(sources?.operations || []),
            },
          });
        });
      });
    };
    setDatastoreSources();

    const setDatastoreData = () => {
      regions.map((region) => {
        if (region.code === selectedBar.region) {
          datastoreNode.push({
            id: region.name,
            title: selectedBar?.name,
            name: region.code,
            latitude: Number(region.latitude),
            longitude: Number(region.longitude),
            full_name: region.full_name,
            code: region.code,
            destinations: updatedDatastoreSourceIds,
            scale: 1.5,
            zoomLevel: 1.5,
            zoomLongitude: 0.1341,
            zoomLatitude: 29.1712,
            color: "#000",
            href: getDatastoreIcon(selectedBar?.type),
            datastore_node: true,
            type: selectedBar?.type,
            is_primary: true,
            node_id: selectedBar?.name,
            CD_Asset_Id: assetId,
            datastoreId: datastoreId,
            eks_enabled: false,
            tooltip_content: {
              name: selectedBar?.name,
              type: selectedBar?.type,
              id: assetId,
            },
          });
        }
      });
    };
    setDatastoreData();

    let sourceSVG =
      "M9,0C4.029,0,0,4.029,0,9s4.029,9,9,9s9-4.029,9-9S13.971,0,9,0z M9,15.93 c-3.83,0-6.93-3.1-6.93-6.93S5.17,2.07,9,2.07s6.93,3.1,6.93,6.93S12.83,15.93,9,15.93 M12.5,9c0,1.933-1.567,3.5-3.5,3.5S5.5,10.933,5.5,9S7.067,5.5,9,5.5 S12.5,7.067,12.5,9z";

    let targetSVG =
      "M9,0C4.029,0,0,4.029,0,9s4.029,9,9,9s9-4.029,9-9S13.971,0,9,0z M9,15.93 c-3.83,0-6.93-3.1-6.93-6.93S5.17,2.07,9,2.07s6.93,3.1,6.93,6.93S12.83,15.93,9,15.93 M12.5,9c0,1.933-1.567,3.5-3.5,3.5S5.5,10.933,5.5,9S7.067,5.5,9,5.5 S12.5,7.067,12.5,9z";

    chart.zoomControl = new am4maps.ZoomControl();
    // Texts
    let labelsContainer = chart.createChild(am4core.Container);
    labelsContainer.isMeasured = false;
    labelsContainer.x = 80;
    labelsContainer.y = 27;
    labelsContainer.layout = "horizontal";
    labelsContainer.zIndex = 10;

    // The world
    let worldPolygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    worldPolygonSeries.useGeodata = true;
    worldPolygonSeries.fillOpacity = 0.6;
    worldPolygonSeries.exclude = ["AQ"];

    let worldPolygonTemplate = worldPolygonSeries.mapPolygons.template;
    worldPolygonTemplate.fill = am4core.color("#323547");
    worldPolygonTemplate.stroke = am4core.color("#323547");

    // Origin series (big targets, London and Vilnius)
    let originImageSeries = chart.series.push(new am4maps.MapImageSeries());
    let originImageTemplate = originImageSeries.mapImages.template;

    var marker = originImageTemplate.createChild(am4core.Image);
    marker.width = 35;
    marker.height = 35;
    marker.nonScaling = true;
    // marker.tooltipText = "Name: {title}";
    marker.horizontalCenter = "middle";
    marker.verticalCenter = "middle";
    marker.propertyFields.href = "href";
    // Setting datastore marker click
    marker.events.on("hit", function (event) {
      setSelectedNode(event?.target?.dataItem?.dataContext);
      setMarkerClicked(true);
    });

    originImageTemplate.propertyFields.latitude = "latitude";
    originImageTemplate.propertyFields.longitude = "longitude";
    originImageTemplate.propertyFields.id = "id";

    originImageTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    originImageTemplate.nonScaling = true;
    originImageTemplate.tooltipText =
      "Name: {title} \n Type: {type} \n Region: {name}";

    originImageTemplate.setStateOnChildren = true;
    originImageTemplate.states.create("hover");

    originImageTemplate.horizontalCenter = "middle";
    originImageTemplate.verticalCenter = "middle";

    // when hit on city, change lines
    originImageTemplate.events.on("hit", function (event) {
      showLines(event.target.dataItem);
    });

    // destination series (small targets)
    let destinationImageSeries = chart.series.push(
      new am4maps.MapImageSeries()
    );

    let destinationImageTemplate = destinationImageSeries.mapImages.template;

    var marker = destinationImageTemplate.createChild(am4core.Image);
    marker.width = 28;
    marker.height = 28;
    marker.nonScaling = true;
    marker.tooltipText = "{title}";
    marker.horizontalCenter = "middle";
    marker.verticalCenter = "middle";
    marker.propertyFields.href = "href";
    // Setting sources marker click
    marker.events.on("hit", function (event) {
      setSelectedNode(event?.target?.dataItem?.dataContext);
      setMarkerClicked(true);
    });

    destinationImageTemplate.nonScaling = true;
    destinationImageTemplate.tooltipText =
      "Name: {userName} \n Source IP Address: {sourceIpAddress} \n {city}";
    destinationImageTemplate.fill = interfaceColors.getFor(
      "alternativeBackground"
    );
    destinationImageTemplate.setStateOnChildren = true;
    destinationImageTemplate.states.create("hover");

    destinationImageTemplate.propertyFields.latitude = "latitude";
    destinationImageTemplate.propertyFields.longitude = "longitude";
    destinationImageTemplate.propertyFields.id = "id";
    destinationImageTemplate.propertyFields.fill = "color";
    destinationImageTemplate.propertyFields.href = "href";

    originImageSeries.data = datastoreNode;
    destinationImageSeries.data = updatedDatastoreSources;

    // Line series
    let lineSeries = chart.series.push(new am4maps.MapSplineSeries());
    lineSeries.mapLines.template.strokeWidth = 1;
    lineSeries.mapLines.template.line.strokeOpacity = 1;
    lineSeries.mapLines.template.line.strokeDasharray = "";
    lineSeries.mapLines.template.propertyFields.fill = "color";
    lineSeries.mapLines.template.nonScalingStroke = true;
    lineSeries.mapLines.template.line.stroke = am4core.color("#D3D3D3");

    chart.events.on("ready", function (ev) {
      showLines(originImageSeries.dataItems.getIndex(0));
    });

    let currentOrigin;

    function showLines(origin) {
      let dataContext = origin.dataContext;
      let destinations = dataContext.destinations;
      // clear old
      lineSeries.mapLines.clear();
      lineSeries.toBack();
      worldPolygonSeries.toBack();

      currentOrigin = origin;
      let line;

      var endDate = moment.utc(datastoreSources?.ten_minutes?.time_window_end); //now
      var currentDate = moment(new Date());
      const difference = currentDate.diff(endDate, "minutes");
      if (destinations) {
        for (var i = 0; i < destinations.length; i++) {
          // Checking live data for last 10mins
          if (difference < 10) {
            lineSeries.mapLines.template.line.strokeDasharray = "5,6";
          } else {
            lineSeries.mapLines.template.line.strokeDasharray = "0,0";
          }
          // For highlighting with colors
          if (
            updatedDatastoreSources[destinations[i] - 1]
              .isComplianceViolation === true
          ) {
            if (rowIndex.index === destinations[i] - 1) {
              lineSeries.mapLines.template.line.stroke = am4core.color(
                "#FC4C02"
              );
              lineSeries.mapLines.template.line.strokeWidth = 2;
            } else {
              lineSeries.mapLines.template.line.stroke = am4core.color(
                "#F89880"
              );
              lineSeries.mapLines.template.line.strokeWidth = 1;
            }
          } else {
            if (rowIndex.index === destinations[i] - 1) {
              lineSeries.mapLines.template.line.stroke = am4core.color(
                "#3260e5"
              );
              lineSeries.mapLines.template.line.strokeWidth = 2;
            } else {
              lineSeries.mapLines.template.line.stroke = am4core.color(
                "#C0C0C0"
              );
              lineSeries.mapLines.template.line.strokeWidth = 1;
            }
          }
          line = lineSeries.mapLines.create();
          line.imagesToConnect = [origin.mapImage.id, destinations[i]];
        }
      }

      line.id = "myline";
      line.setClassName();
      // title.text = "Flights from " + dataContext.title;

      chart.zoomToGeoPoint(
        {
          latitude: dataContext.zoomLatitude,
          longitude: dataContext.zoomLongitude,
        },
        dataContext.zoomLevel,
        true
      );
    }

    let graticuleSeries = chart.series.push(new am4maps.GraticuleSeries());
    graticuleSeries.mapLines.template.line.strokeOpacity = 0.05;
  };

  useEffect(() => {
    if (selectedBar?.cloudAccountId && datastoreSources) {
      loadChart();
    }
  }, [rowIndex, selectedTab, isUSOnlyAccess, datastoreSources, selectedBar]);

  // useEffect(() => {
  //   if (selectedBar?.cloudAccountId && datastoreSources) {
  //     loadChart();
  //   }
  // }, [datastoreSources, selectedBar]);

  return (
    <>
      <div
        id="world-map"
        className="mt-1"
        style={{ height: "340px", width: "100%" }}
      ></div>
      {markerClicked && (
        <AffectedDatastoresAndIssuesModal
          handleModal={() => setMarkerClicked(false)}
          isRiskAccepted={false}
          assetIdValue={selectedNode.CD_Asset_Id}
          node={selectedNode}
          isAttackPath={true}
          selectedTab={selectedTab}
          isSecurityIssueVisisble={true}
        />
      )}
    </>
  );
};

export default WorldMap;
